import React, { Component } from 'react'
import { Row, Col, Button, Image } from 'react-bootstrap'
import * as qs from 'query-string'

import validateInput from '../../../common/validations/validationResetPassword'
import lifeSavorApiService from '../../../common/services/apiService'
import ResetPasswordImage from '../../../assets/images/resetPasswordImage.svg'
import Form from 'react-bootstrap/Form'
import CONSTANTS from '../../../common/helpers/Constants'
import { ReactComponent as ShowEyeicon } from '../../../assets/images/eye-slash.svg'
import { ReactComponent as HideEyeicon } from '../../../assets/images/eye-svgrepo.svg'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      userId: '',
      roleType: '',
      errors: {},
      showLoader: false,
      newShowPassword: false,
      confirmShowPassword: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    if (location) {
      const parsed = qs.parse(location.search)
      let token = parsed.token
      this.setState({
        token,
      })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if (!isValid) {
      this.setState({ errors })
    }
    return isValid
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }
  handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
  }
  handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')
    if (/\s/.test(pastedData)) {
      event.preventDefault()
      const sanitizedData = pastedData.replace(/\s/g, '')
      document.execCommand('insertText', false, sanitizedData)
    }
  }

  handleSubmit = () => {
    this.setState({
      showLoader: true,
    })

    if (this.isValid()) {
      let data = {
        token: this.state.token,
        newPassword: btoa(this.state.password),
        role: 1,
      }
      lifeSavorApiService('resetPassword', data)
        .then((result) => {
          console.log(result)
          if (result && result.data && result.data.status === 200) {
            this.props.history.push({
              pathname: '/login',
            })
            this.setState({
              password: '',
              confirmPassword: '',
              errors: {},
              showLoader: false,
            })
          } else {
            this.setState({
              showLoader: false,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      this.setState({
        showLoader: false,
      })
    }
  }
  showPass = (type, val) => {
    this.setState({
      [type]: !val,
    })
  }
  render() {
    const { errors } = this.state
    return (
      <div className="page__wrapper">
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth login">
              <div className="row w-100">
                <div className="col-lg-8 mx-auto">
                  <div className="deliva-form boxShadow1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center justify-content-center py-5 h-100">
                          <div className="login-wrap">
                            <h4 className="text-info font-weight-normal mb-4">
                              Reset Password
                            </h4>

                            <Form>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                  error={errors.password ? true : false}
                                  type={
                                    this.state.newShowPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  maxLength={CONSTANTS.MAXLENGTH.PASSWORD}
                                  fullWidth
                                  id="outlined-pwd"
                                  variant="outlined"
                                  name="password"
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  onKeyPress={this.handleEnter}
                                  onKeyDown={this.handleKeyDown}
                                  onPaste={this.handlePaste}
                                  placeholder="New Password"
                                  className="login-input reset-password"
                                />
                                <div
                                  onClick={() =>
                                    this.showPass(
                                      'newShowPassword',
                                      this.state.newShowPassword
                                    )
                                  }
                                  className="pwd-icons"
                                >
                                  {!this.state.newShowPassword ? (
                                    <div className="pointer">
                                      <ShowEyeicon />
                                    </div>
                                  ) : (
                                    <div className="pointer">
                                      <HideEyeicon />
                                    </div>
                                  )}
                                </div>
                                <div className="error alignLeft">
                                  {errors.password}
                                </div>
                              </Form.Group>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                  type={
                                    this.state.confirmShowPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  placeholder="Confirm Password"
                                  className="login-input reset-password"
                                  error={errors.confirmPassword ? true : false}
                                  maxLength={CONSTANTS.MAXLENGTH.PASSWORD}
                                  fullWidth
                                  id="outlined-cpwd"
                                  variant="outlined"
                                  name="confirmPassword"
                                  value={this.state.confirmPassword}
                                  onKeyPress={this.handleEnter}
                                  onChange={this.handleChange}
                                  onKeyDown={this.handleKeyDown}
                                  onPaste={this.handlePaste}
                                />
                                <div
                                  onClick={() =>
                                    this.showPass(
                                      'confirmShowPassword',
                                      this.state.confirmShowPassword
                                    )
                                  }
                                  className="pwd-icons"
                                >
                                  {!this.state.confirmShowPassword ? (
                                    <div className="pointer">
                                      <ShowEyeicon />
                                    </div>
                                  ) : (
                                    <div className="pointer">
                                      <HideEyeicon />
                                    </div>
                                  )}
                                </div>

                                <div className="error alignLeft">
                                  {errors.confirmPassword}
                                </div>
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="button"
                                className="deliva-btn resetpass mb-5 mt-4"
                                onClick={this.handleSubmit}
                              >
                                Save
                              </Button>
                            </Form>
                            <a
                              className="auth-link pointer text-grey"
                              role="button"
                              onClick={() => this.props.history.push('/')}
                            >
                              back to <span className="text-blue">Home</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="d-flex align-items-center justify-content-center p-5 h-100">
                          <Image
                            src={ResetPasswordImage}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ResetPassword
