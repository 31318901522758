import React, { Component } from 'react';
import Sidebar from '../commonAdmin/Sidebar';
import Header from '../commonAdmin/Header';
import Footer from '../commonAdmin/Footer';
import { Row, Col, Image, FormControl } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Unblock from '../../../assets/images/Unblock.svg';
import Modal from 'react-bootstrap/Modal';
import userblock1 from '../../../assets/images/userblock1.svg';
// import "../../../App.scss"
import Loader from '../../common/Loader';
import delivaApiService from '../../../common/services/apiService';
import { getLocalStorage, range } from '../../../common/helpers/Utils';
import CONSTANTS from '../../../common/helpers/Constants';
import moment from 'moment';
import Template from '../../../common/helpers/Template';

class OrderTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      delivery_agent_list: [],
      pageno: 1,
      records: 10,
      totalCount: '',
      sortBy: '',
      sortOrder: 'desc',
      keyword: '',
      search: '',
      showInviteVendors: false,
      blockedDueToCancellationLimit: '',
    };
  }

  getOrders(pageno, records, sortBy, sortOrder, search) {
    this.setState({
      delivery_agent_list: [],
      // showLoader: true,
      pageno: pageno,
      records: records,
      sortBy: sortBy,
      sortOrder: sortOrder,
      search: search
    });
    this.setState(
      {
        apiName:
          this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL
            ? 'getTotalOrders'
            : this.state.pageName == CONSTANTS.ORDERTYPES.CANCELLED
              ? 'getCancelledOrders'
              : this.state.pageName == CONSTANTS.ORDERTYPES.INTRANSIT
                ? 'getInTransitOrders'
                : this.state.pageName == CONSTANTS.ORDERTYPES.DELIVERED
                  ? 'getCompletedOrders'
                  : '',
      },
      () => {
        this.setLoader(true);
        delivaApiService(this.state.apiName, {
          pageNumber: pageno,
          records: records,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchKey: this.state.search,
        })
          .then((response) => {
            this.setLoader(false);
            // if (response && response.data && response.data.resourceData) {
            this.setState(
              {
                delivery_agent_list: response.data.resourceData,
                totalCount: response.data.totalRecords,
              },
              this.getPager(response.data.totalRecords)

            );
            // }
          })
          .catch((err) => {

          });
      }
    );
  }

  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    });
  };

  componentDidMount() {
    this.setState(
      {
        pageName: this.props.match.params.name,
      },
      () => {
        this.getOrders(this.state.pageno, this.state.records, this.state.sortBy, this.state.sortOrder, this.state.search);
      }
    );
  }

  //manage page counts
  getPager(total) {
    let startPage = this.state.startPage;
    let endPage = this.state.endPage;
    let totalPage = Math.ceil(total / this.state.records);
    let pageno = this.state.pageno;

    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (pageno <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = pageno - 2;
        endPage = pageno + 2;
      }
    }
    let startIndex = (pageno - 1) * this.state.records;
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1);

    // create an array of pages to ng-repeat in the pager control
    let pageArray;
    if (startPage == endPage) {

      pageArray = [1];
    } else {
      pageArray = range(startPage, endPage);
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    });
  }

  // change page

  onChangePage(page) {

    if (page == this.state.pageno) {
    } else {
      this.getOrders(page, this.state.records,this.state.sortBy,this.state.sortOrder,this.state.search);
    }
  }

  //change data per page
  onChangeItemPerPage(e) {

    if (e.target.value && e.target.value > 0) {
      this.getOrders(1, e.target.value,this.state.sortBy,this.state.sortOrder,this.state.search);
    } else {
      this.setState({
        records: '',
      });
    }
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      if (this.state.search.length == 0) {
        this.getOrders(this.state.pageno, this.state.records,this.state.sortBy,this.state.sortOrder,this.state.search);
      }
    });
  };

  handleEnter = (e) => {
    if (e.key == 'Enter') {
      this.getOrders(this.state.pageno, this.state.records, this.state.sortBy, this.state.sortOrder,this.state.search);
    }
  };

  handleSearch() {
    if (this.state.search.trim() != '') {
      this.getOrders(this.state.pageno, this.state.records, this.state.sortBy, this.state.sortOrder,this.state.search);
    }
  }

  sortDeliveryAgent(val) {
    this.setState({ sortOrder: this.state.sortOrder == 'desc' ? '' : 'desc' });
    if (this.state.sortOrder == 'desc') {
      this.getOrders(this.state.pageno, this.state.records, val, 'asc',this.state.search);
    } else {
      this.getOrders(this.state.pageno, this.state.records, val, 'desc',this.state.search);
    }
  }
  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }
  handleRouteChange(val) {
    this.props.history.push(val);
  }
  changepath = (path, props) => {
    this.props.history.push(path);
  };
  orderStatus = (status) => {
    if (status === CONSTANTS.ORDER_STATUS.TRANSIT) {
      return 'In transit';
    } else if (status === CONSTANTS.ORDER_STATUS.ACCEPTED) {
      return 'Accepted bid';
    } else if (status === CONSTANTS.ORDER_STATUS.DELIVERED) {
      return 'Delivered';
    } else if (status == CONSTANTS.ORDER_STATUS.CANCELLED) {
      return 'Cancelled';
    } else if (status === CONSTANTS.ORDER_STATUS.REJECTED) {
      return 'Pickup rejected';
    }
  };

  render() {
    const { delivery_agent_list } = this.state;
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />
        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs22">
                        {this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL
                          ? Template.TOTALORDERS
                          : this.state.pageName == CONSTANTS.ORDERTYPES.CANCELLED
                            ? 'Cancelled Orders'
                            : this.state.pageName == CONSTANTS.ORDERTYPES.INTRANSIT
                              ? 'In-Transit Orders'
                              : this.state.pageName == CONSTANTS.ORDERTYPES.DELIVERED
                                ? Template.COMPLETEDORDERS
                                : ''}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht fs18 color-head">
                        <span
                          className="pointer"
                          onClick={() =>
                            this.handleRouteChange('/dashboard')
                          }
                        >
                          Dashboard
                        </span>

                        <span className="icon-next mx-2 Fs12"></span>
                        {this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL
                          ? Template.TOTALORDERS
                          : this.state.pageName == CONSTANTS.ORDERTYPES.CANCELLED
                            ? 'Cancelled Orders'
                            : this.state.pageName == CONSTANTS.ORDERTYPES.INTRANSIT
                              ? 'In-transit Orders'
                              : this.state.pageName == CONSTANTS.ORDERTYPES.DELIVERED
                                ? Template.COMPLETEDORDERS
                                : ''}
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.search || ''}
                        onChange={this.handleChangeInput}
                        onKeyDown={this.handleEnter.bind(this)}
                        label="Search"
                        type="text"
                        // placeholder={this.state.pageName!=='cancelled' ? "Search by Order ID, OPC, DPC":"Search by Order ID, Order date"}
                        placeholder={"Search by Order ID, Customer Name"}
                        className="searchTxt w-100"
                      />
                      <span
                        onClick={() => this.handleSearch()} className="icon-search"></span>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap statistics-table">
                        <Table className='inTransitOrderTable'>
                          <thead>
                            <tr>
                              <th>
                                Order date{' '}
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.sortDeliveryAgent('orderDate')
                                  }
                                  className="icon-up-and-down color-light-grey"
                                ></a>
                              </th>
                              <th>
                                Order id{' '}
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.sortDeliveryAgent('orderId')
                                  }
                                  className="icon-up-and-down color-light-grey"
                                ></a>
                              </th>
                              <th>
                                Customer{' '}
                                <a
                                  href="#"
                                  onClick={() => this.sortDeliveryAgent('customerName')}
                                  className="icon-up-and-down color-light-grey"
                                ></a>
                              </th>
                              {this.state.pageName !== CONSTANTS.ORDERTYPES.CANCELLED ?
                                <th>
                                  OPC{' '}
                                  <a
                                    href="#"
                                    onClick={() =>
                                      this.sortDeliveryAgent('pickUpLocation')
                                    }
                                    className="icon-up-and-down color-light-grey"
                                  ></a>
                                </th>
                                : null}
                              {this.state.pageName !== CONSTANTS.ORDERTYPES.CANCELLED ?
                                <th>
                                  DPC{' '}
                                  <a
                                    href="#"
                                    onClick={() => this.sortDeliveryAgent('destinationLocation')}
                                    className="icon-up-and-down color-light-grey"
                                  ></a>
                                </th>
                                : null}
                              <th>
                                Deliver Agent{' '}
                                <a
                                  href="#"
                                  onClick={() => this.sortDeliveryAgent('deliveryAgentName')}
                                  className="icon-up-and-down color-light-grey"
                                ></a>
                              </th>
                              {this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL ?
                                <th>
                                  Status{' '}
                                </th>
                                : null}
                              {this.state.pageName !== CONSTANTS.ORDERTYPES.TOTAL ?
                                <th>
                                  Receiver{' '}
                                  <a
                                    href="#"
                                    onClick={() =>
                                      this.sortDeliveryAgent('receiverName')
                                    }
                                    className="icon-up-and-down color-light-grey"
                                  ></a>
                                </th>
                                : null}
                              <th>
                                Action{' '}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ttl-order-table">
                            {this.state?.delivery_agent_list?.map((item, i) => {
                              return (
                                <tr key={Math.random()}>
                                  <td>{moment(item.orderDate).format('DD MMM YYYY')}</td>
                                  <td>{item.orderId}</td>
                                  <td>{item.customerName}</td>
                                  {this.state.pageName !== CONSTANTS.ORDERTYPES.CANCELLED ? <td>{item.pickUpLocation}</td> : null}
                                  {this.state.pageName !== CONSTANTS.ORDERTYPES.CANCELLED ? <td>{item.destinationLocation}</td> : null}
                                  <td>{item.deliveryAgentName}</td>
                                  {this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL ? 
                                  <td className={this.orderStatus(item.status) === "Delivered" ? "text-green" : this.orderStatus(item.status) === "In transit" ? "text-orange" : "text-red"}>
                                    {this.orderStatus(item.status)}</td> 
                                  : null}
                                  {this.state.pageName !== CONSTANTS.ORDERTYPES.TOTAL ? <td>{item.receiverName}</td> : null}
                                  <td>
                                    <a
                                      className="pointer"
                                      onClick={() =>
                                        this.changepath(
                                          '/' + this.state.pageName + '/details/' + item.orderId
                                        )
                                      }
                                    >
                                      <span className="icon-eye"></span> View
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <nav aria-label="Page navigation">
                        <ul className="pagination pg-blue deliva-pagination justify-content-end">
                          <li className="page-item">
                            <button
                              className="page-link rotate-180 control-btn"
                              aria-label="Previous"
                              onClick={() =>
                                this.onChangePage(this.state.pageno - 1)
                              }
                              disabled={
                                this.state.pageno == 1 ||
                                this.state.totalPage == 0
                              }
                            >
                              <span className="icon-next"></span>
                              <span
                                //className="prevNext"
                                className={`sr-only ${this.state.pageno == 1 ||
                                  this.state.totalPage == 0
                                  ? ''
                                  : 'active'
                                  }`}
                              >
                                Previous
                              </span>
                            </button>
                          </li>

                          {this.state.totalPage > 0 &&
                            this.state.pageArray.map((page, ind) => {
                              return (
                                <li className="page-item" key={Math.random()}>
                                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                  <a
                                    className={`page-link ${this.state.pageno == page ? 'active' : ''
                                      }`}
                                    onClick={() => this.onChangePage(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              );
                            })}

                          <li className="page-item">
                            <button
                              className="page-link control-btn"
                              aria-label="Next"
                              onClick={() =>
                                this.onChangePage(this.state.pageno + 1)
                              }
                              disabled={
                                this.state.pageno == this.state.totalPage ||
                                this.state.totalPage == 0
                              }
                            >
                              <span className="icon-next"></span>
                              <span
                                className={`sr-only ${this.state.pageno == this.state.totalPage ||
                                  this.state.totalPage == 0
                                  ? ''
                                  : 'active'
                                  }`}
                              >
                                Next
                              </span>
                            </button>
                          </li>

                          <li className="page-value">
                            Enter Per Page{' '}
                            <input
                              type="text"
                              placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                              name="records"
                              value={this.state.records}
                              onChange={(e) => this.onChangeItemPerPage(e)}
                            />
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default OrderTypes;
