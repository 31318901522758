import delivaApiService from '../../services/apiService';
import { LOGIN, LOGOUT } from '../types';

export const actionLogin = data => async dispatch => {
  const request = await delivaApiService('login', data);
  dispatch({ type: LOGIN, payload: request });

  return request;
};

export const actionLogout = data => {
  // const request = delivaApiService('logout', data);
  return { type: LOGOUT, payload: '' };
  // return request;
};
