export default {
  login: {
    url: '/userauth/oauth/token?username=:username&password=:password&roleId=:roleId&loginBy=:loginBy&grant_type=:grant_type&deviceId=:deviceId&deviceType=web&countryCode=0&timezone=:timezone',
    method: 'POST',
    data: {
      username: '',
      password: '',
      roleId: '',
      loginBy: 'email',
      grant_type: 'password',
      deviceId: '',
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  forgotPassword: {
    url: '/admin/admin/forgotPassword',
    method: 'POST',
    data: {
      email: '',
      role: 1,
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  resetPassword: {
    url: '/admin/admin/resetPassword',
    method: 'POST',
    data: {
      newPassword: '',
      role: 1,
      token: '',
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  createAdmin: {
    url: '/admin/admin/createAdmin',
    method: 'POST',
    data: {
      name: '',
      mobile: 1,
      email: '',
      active: true,
      city: '',
      country: '',
      roleId: 1,
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  orderStatistics: {
    url: '/admin/admin/getOrderStatistics',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllCustomers: {
    url: '/admin/customer/getAllCustomers?records=:records&pageNumber=:pageNumber&sortBy=:sortBy&sortOrder=:sortOrder&searchByProfile=:searchByProfile&searchByLocation=:searchByLocation',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllAdmin: {
    url: '/admin/admin/getAllAdmin?records=:records&pageNumber=:pageNumber&sortBy=:sortBy&sortOrder=:sortOrder&searchByProfile=:searchByProfile',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },

  getAdminProfile: {
    url: '/admin/admin/getAdminProfile/:userId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllNotification: {
    url: 'admin/admin/getAllNotification/:userId?records=:record&pageNumber=:pageNumber',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllTransactionHistory: {
    url: 'admin/admin/getAllTransactionHistory/:userId?page=:page&size=:size',
    method: 'GET',
    data: {
      userId: '',
      page: '',
      size: '',
    },
    showResultMessage: false,
    showErrorMessage: true,
  },
  deleteAdmin: {
    url: 'admin/admin/deleteAdmin/:userId/:loginUserId',
    method: 'PUT',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllAdminRole: {
    url: 'admin/admin/getAllAdminRole',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  updateAdminProfile: {
    url: 'admin/admin/editAdminDetail',
    method: 'PUT',
    data: {
      userId: '',
      name: '',
      email: '',
      country: '',
      city: '',
      roleId: '',
      active: '',
      mobile: '',
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  pendingApprovals: {
    url: '/admin/admin/getPendingApprovals',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  siteStatistics: {
    url: '/admin/admin/getSiteStatistics',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getListOfCancelledRequests: {
    url: '/admin/admin/getListOfCancelledRequests',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllPAandDAPendingForApproval: {
    url: '/admin/deliveryAgent/getAllPAandDAPendingForApproval/:roleId?records=:records&pageNumber=:pageNumber&sortBy=:sortBy&sortOrder=:sortOrder&searchByProfile=:searchByProfile&userId=:userId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllOrders: {
    url: '/admin/admin/getTotalOrders',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  // http://localhost:8711/admin/admin/getTotalOrders?pageNumber=1&records=10&searchKey=&sortBy=requestId&sortOrder=ASC
  getTotalOrders: {
    url: '/admin/admin/getTotalOrders?pageNumber=:pageNumber&records=:records&searchKey=:searchKey&sortBy=:sortBy&sortOrder=:sortOrder',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getCancelledOrders: {
    url: '/admin/admin/getListOfCancelledRequests?pageNo=:pageNumber&records=:records&searchKey=:searchKey&sortBy=:sortBy&sortOrder=:sortOrder',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getInTransitOrders: {
    url: '/admin/admin/getListOfInTransitRequests?pageNo=:pageNumber&records=:records&searchKey=:searchKey&sortBy=:sortBy&sortOrder=:sortOrder',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getCompletedOrders: {
    url: '/admin/admin/getListOfDeliveredRequests?pageNo=:pageNumber&records=:records&searchKey=:searchKey&sortBy=:sortBy&sortOrder=:sortOrder',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getOrderDetailByOrderId: {
    url: 'admin/admin/getOrderDetailByOrderId/:orderId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getListOfDeliveredRequests: {
    url: '/admin/admin/getListOfDeliveredRequests',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getListOfInTransitRequests: {
    url: '/admin/admin/getListOfInTransitRequests',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getListOfOpenRequests: {
    url: '/admin/admin/getListOfOpenRequests',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  deliveryAgentList: {
    url: '/admin/deliveryAgent/getAllDeliveryAgent?records=:records&pageNumber=:pageNumber&blockedDueToCancellationLimit=:blockedDueToCancellationLimit&sortBy=:sortBy&sortOrder=:sortOrder&searchByProfile=:searchByProfile',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getCancellationDetail: {
    url: '/admin/admin/getCancellationDetail/:requestId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  processingAgentList: {
    url: '/admin/processingAgent/getAllProcessingAgent?records=:records&pageNumber=:pageNumber&sortBy=:sortBy&sortOrder=:sortOrder&searchByProfile=:searchByProfile&searchByLocation=:searchByLocation',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getPackageCountForPA: {
    url: '/admin/processingAgent/getPackageCountForPA/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getTotalOrderProcessedPA: {
    url: '/admin/processingAgent/getTotalOrderProcessed/:id?records=:records&pageNumber=:pageNumber',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getTotalOrdersForDA: {
    url: '/admin/deliveryAgent/getTotalOrdersForDA/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },

  getOpenBidsForDA: {
    url: '/admin/deliveryAgent/getOpenBidsForDA/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getAllTripsForDA: {
    url: '/admin/deliveryAgent/getAllTripsForDA/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getDeliveryAgentDetail: {
    url: '/admin/deliveryAgent/getDeliveryAgentDetail/:orderId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  // GET /deliveryAgent/getOrderAndBidCountForDA/{daId}
  getSubmittedBidsForDA: {
    url: '/admin/deliveryAgent/getSubmittedBidsForDA/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  // GET /deliveryAgent/getSubmittedBidsForDA/{daId}
  getOrderAndBidCountForDA: {
    url: '/admin/deliveryAgent/getOrderAndBidCountForDA/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },

  getAllPAandDAForApproval: {
    url: 'admin/deliveryAgent/getAllPAandDAForApproval/:roleId?records=:records&pageNumber=:pageNumber&sortBy=:sortBy&sortOrder=:sortOrder&searchByProfile=:searchByProfile&adminLoginId=:userId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  customerProfileDeliveryRequestFilter: {
    url: 'admin/customer/customerProfileDeliveryRequestFilter?customerId=:customerId&origin=:origin&destination=:destination&status=:status&from=:form&to=:to',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getProcessingAgentDetail: {
    url: '/admin/processingAgent/getProcessingAgentDetail/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  blockUnblockCustomer: {
    url: '/admin/admin/blockAndUnblockUser/:userId',
    method: 'PUT',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },

  deliveryAgentprofile: {
    url: '/admin/deliveryAgent/getDeliveryAgentDetail/:daId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },

  deliveryAgentprofileCount: {
    url: '/admin/deliveryAgent/getOrderAndBidCountForDA/:daId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  customerProfileDetail: {
    url: '/admin/customer/customerProfileDetail/:customerId',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getTotalOrderProcessed: {
    url: 'admin/deliveryAgent/getTotalOrderProcessed/:id',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  customerProfileDeliveryRequestFilter: {
    url: `/admin/customer/customerProfileDeliveryRequestFilter?customerId=:id&origin=:origin&destination=:destination&status=:status&from=:from&to=:to`,
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  getProcessingAgentList: {
    url: '/admin/processingAgent/getProcessingAgentList',
    method: 'GET',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  approveDA: {
    url: '/admin/admin/approveUser/:userId',
    method: 'PUT',
    data: {},
    showResultMessage: false,
    showErrorMessage: true,
  },
  rejectDA: {
    url: '/admin/admin/rejectUser',
    method: 'PUT',
    data: {
      reasonForRejection: '',
      rejectionDetail: '',
      userId: '',
      docsResubmissionBlocked: '',
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  readNotification: {
    url: '/user/notification/readNotification/:notificationId',
    method: 'PUT',
    data: {
      notificationId: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  getListOfCompletedOrdersToBePaid: {
    url: '/admin/admin/getListOfCompletedOrdersToBePaid?roleId=:roleId&pageSize=:pageSize&pageNo=:pageNo&searchKey=:searchKey&sortBy=:sortBy&sortOrder=:sortOrder&listFor=:listFor',
    method: 'GET',
    data: {
      roleId: '',
      pageNo: '',
      pageSize: '',
      searchKey: '',
      sortBy: '',
      sortOrder: '',
      listFor: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  getOrderDetailofOrderList: {
    url: '/admin/admin/getPaymentDetailOfAnOrder?orderId=:orderId&userId=:userId',
    method: 'GET',
    data: {
      orderId: '',
      userId: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  getSetting: {
    url: '/user/adminUser/transactionSetup',
    method: 'GET',
    data: {
      orderId: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  referralCommission: {
    url: '/user/adminUser/addTransactionFrequency',
    method: 'POST',
    data: {
      referralCommission: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  delivaCommission: {
    url: '/user/adminUser/addTransactionFrequency',
    method: 'POST',
    data: {
      delivaCommission: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  paymentCycle: {
    url: '/user/adminUser/addTransactionFrequency',
    method: 'POST',
    data: {
      paymentCycle: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  paCommission: {
    url: '/user/adminUser/addTransactionFrequency',
    method: 'POST',
    data: {
      paCommission: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  // http://125.99.189.199:8711/user/payment/transferAmount

  transferFunds: {
    url: '/admin/admin/transferAmount',
    method: 'POST',
    data: {
      userId: '',
      amount: '',
      deliveryRequestId: '',
      currency: '',
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  transferAllFunds: {
    url: '/admin/admin/transferAllAmount',
    method: 'POST',
    data: {
      transferList: [],
    },
    showResultMessage: false,
    showErrorMessage: true,
  },
  holdPayment: {
    url: 'admin/admin/holdUnholdTransfer?roleId=:roleId',
    method: 'POST',
    data: {
      orderId: '',
      hold: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
  getStatusBasedList: {
    url: '/user/payment',
    method: 'POST',
    data: {
      order: '',
    },
    showResultMessage: false,
    showErrorMessage: true,
  },
  getReferralList: {
    url: '/admin/admin/getReferralList?userId=:userId&page=:page&size=:size&sortBy=:sortBy&sortOrder=:sortOrder',
    method: 'GET',
    data: {
      userId: '',
      page: '',
      size: '',
      sortBy: '',
      sortOrder: '',
    },
    showResultMessage: false,
    showErrorMessage: false,
  },
}
