import React, { Component } from "react";
// import { render } from 'react-dom';

// react table
// import * as ReactTable from 'react-table'
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
// import { makeData, Logo, Tips } from "./Util";
import matchSorter from 'match-sorter'

// import "react-table/react-table.css";

// import Hello from './Hello';
// import './style.css';

class Tableapp extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      data: [
        {
          name: "pp Linsley",
          id: 23,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "zz Linsley",
          id: 22,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "ab Linsley",
          id: 2,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
        {
          name: "Tanner Linsley",
          id: 26,
          friend: {
            name: "Jason Maurer",
            age: 23,
          },
        },
      ],
    };
    this.filterAll = this.filterAll.bind(this);
  }

  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;
          insertNewFilter = 0;
        }
      });
    }
    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  };

  renderItem(item) {
    const array = [
      {
        id: 1,
        val: "hello 1",
      },
      {
        id: 2,
        val: "hello 2",
      },
      {
        id: 3,
        val: "hello 3",
      },
      {
        id: 4,
        val: "hello 4",
      },
      {
        id: 5,
        val: "hello 5",
      },
      {
        id: 6,
        val: "hello 6",
      },
    ];

    const filtered = array.filter((x) => x.id === parseInt(item, 10))[0];
    if (filtered) {
      return filtered.val;
    } else {
      return "INVALID";
    }
  }
  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }

  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    console.log('filtered', filtered);
    // NOTE: this completely clears any COLUMN filters
    this.setState({ filterAll, filtered });
  }

  render() {
    const columns = [
        
      {
        Header: "Name",
        id: "name",
        accessor: d => d.name,
        Cell: (props) => <span >{props.value}</span>, // Custom cell components!

        // accessor: "name", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
      },
      {
        Header: "Age",
        accessor: "id",
        filterMethod: (filter, row) => {
          return row[filter.id].includes(filter.value);
        },
        Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        id: "friendName", // Required because our accessor is not a string
        Header: "Friend Name",
        accessor: (d) => d.friend.name, // Custom value accessors!
      },
      {
        Header: (props) => <span>Friend Age</span>, // Custom header components!
        accessor: "friend.age",
      },
      {
        // NOTE - this is a "filter all" DUMMY column
        // you can't HIDE it because then it wont FILTER
        // but it has a size of ZERO with no RESIZE and the
        // FILTER component is NULL (it adds a little to the front)
        // You culd possibly move it to the end
        Header: "All",
        id: 'all',
        width: 0,
        resizable: false,
        sortable: false,
        Filter: () => { },
        getProps: () => {
          return {
            // style: { padding: "0px"}
          }
        },
        filterMethod: (filter, rows) => {         
          const result = matchSorter(rows, filter.value, {
            keys: [
              "name",
              "friendName"
            ], threshold: matchSorter.rankings.WORD_STARTS_WITH
          });
          console.log('row[0]:', result[0]);
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div>
        Filter All:{" "}
        <input
          value={this.state.filterAll}
          onChange={(e) => this.filterAll(e)}
        />
        <ReactTable
          data={this.state.data}
          columns={columns}
          defaultPageSize={10}
          filtered={this.state.filtered}
          ref={(r) => (this.reactTable = r)}
          onFilteredChange={this.onFilteredChange.bind(this)}
        //   filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.age]) === filter.value
          }
        />
      </div>
    );
  }
}

export default Tableapp;
