import isEmpty from 'lodash/isEmpty';
//import ValidationMessages from '../helpers/ValidationMessages';

function validateInput(data) {
  let errors = {};



  if (data.bankVerification === false) {
    errors.bankVerification = 'Please verify bank details';
  }

  if (data.timeVerification === false) {
    errors.timeVerification = 'Please verify operational hours';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export default validateInput;
