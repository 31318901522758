import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import matchSorter from "match-sorter";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col } from "react-bootstrap";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage } from "../../../common/helpers/Utils";
import Pagination from "./perfectpage";
import moment from "moment";
import './TripTablesStyle.scss';
class DeliveryAgentTotalOrders extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      searchInput: "",
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: "default", value: 5 }],
      pageSize: 5,
    };
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {
    this.getDAOrders();
  }
  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }
  onRezise(page) {
    console.log(page);
    // if (filtered.length > 1 && this.state.filterAll.length) {
    //   // NOTE: this removes any FILTER ALL filter
    //   const filterAll = "";
    //   this.setState({
    //     filtered: filtered.filter((item) => item.id != "all"),
    //     filterAll,
    //   });
    // } else this.setState({ filtered });
  }
  filterAll(e) {
    console.log(e);
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    console.log(filtered);
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.details.filter((value) => {
      return (        
        value.travelDesCity.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.travelFrmCity.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase())         
      );
    });
    this.setState({ data: filteredData });
  };

  getDAOrders() {
    // this.setLoader(true);
    delivaApiService("getAllTripsForDA", { id: getLocalStorage("DAid") })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState(
            {
              details: response.data.resourceData.tripDetail,
              totalTrips: response.data.resourceData.totalTrips,
            },
            () => {
              this.setState({
                data: this.state.details.map((item) => {
                  return item;
                }),
              });
            }
          );
        } else {
          //   this.setLoader(false);
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      });
  }
  hello(pageSize) {
    console.log("hi");
    this.setState({
      pageSize,
    });
  }
  render() {
    const columns = [
      // "destinationLocation","orderDate","originatingLocation","status","title
      //       associatedOrders: 0
      // travelDate: "2020-06-24 00:00:00.0 - 2020-06-25 00:00:00.0"
      // travelDesCity: "Bombay"
      // travelFrmCity: "Indore"
      // tripId: 8
      // userId: 78
      {
        Header: "Travelling Date ",
        id: "travelDate",
        accessor: (d) =>
          moment(new Date(d.travelDate.split(" - ")[0])).format("DD MMM YY") +
          " - " +
          moment(new Date(d.travelDate.split(" - ")[1])).format("DD MMM YY"),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "travelDate", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["travelDate"] }),
        filterAll: true,
      },

      {
        Header: "Travelling From",
        id: "travelFrmCity",
        accessor: (d) => d.travelFrmCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "travelFrmCity", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["travelFrmCity"] }),
        filterAll: true,
      },
      {
        Header: "Travelling To",
        id: "travelDesCity",
        accessor: (d) => d.travelDesCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "travelDesCity", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["travelDesCity"] }),
        filterAll: true,
      },
      {
        Header: "Status",
        id: "status",
        accessor: (d) => d.status,
        Cell: (props) => (
          <span className={`${props.value == "COMPLETED" ? "text-green" : "text-reddish"}`}>
            {props.value == "COMPLETED" ? "COMPLETED" : "In-Process"}
          </span>
        ),      
        // accessor: "status", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["status"] }),
        filterAll: true,
      },

      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          console.log(filter);
          console.log(rows);
          const result = matchSorter(rows, filter.value, {
            keys: [
              //   "destinationLocation",
              "travelFrmCity",
              "travelDesCity",
              //   "status",
              //   "title",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          console.log("row[0]:", result[0]);
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33">
                        Total Trips{" "}
                        <span className="color-grey">
                          ({this.state.totalTrips})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-head">
                      <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentlist'
                            )
                          }
                          className="pointer"
                        >
                        Delivery agents
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentprofile'
                            )
                          }
                          className="pointer"
                        > Delivery agent’s profile{" "}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        Total Trips
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by Travelling From, Travelling To"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap">
                        <ReactTable
                          data={this.state.data}
                          columns={this.state.pageSize ? columns : []}
                          //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={this.state.pageSize}
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          //   resized={this.state.resized}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                            console.log(pageSize, pageIndex);
                          }}
                          //   filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                          PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                          Enter Per Page{" "}
                          <input
                            type="text"
                            placeholder="07"
                            name="defaultPageSize"
                            value={this.state.pageSize}
                            onChange={(e) => this.hello(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default DeliveryAgentTotalOrders;
