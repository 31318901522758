import React, { Component } from 'react'
import uniq from 'lodash/uniq'
import { Row, Col, Image } from 'react-bootstrap'
import logo from '../../../assets/images/logo.svg'
import ProfileImg from '../../../assets/images/user.png'
import Dropdown from 'react-bootstrap/Dropdown'
import delivaApiService from '../../../common/services/apiService'
import {
  getLocalStorage,
  clearLocalStorage,
  timeSince,
  removedUnderscore,
} from '../../../common/helpers/Utils'
import CONSTANTS from '../../../common/helpers/Constants'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notification: [],
      notificationLength: 0,
      showLoader: false,
      pageNumber: 1,
      pageSize: CONSTANTS.NOTIFICATIONACTIONS.PAGINATIONSIZE,
    }
  }
  componentDidMount() {
    this.getNotifications(getLocalStorage('id'))
  }

  handleLogout() {
    clearLocalStorage()
    this.props.history.push('/login')
  }

  getNotifications = (id) => {
    let data = {
      userId: id,
      record: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }
    delivaApiService('getAllNotification', data)
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState({
            notification: uniq([
              ...this.state.notification,
              ...response.data.resourceData,
            ]),
            notificationLength:
              response.data.noOfRecords || this.state.notification?.length,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  handleRedirectDashboard() {
    this.props.history.push('/dashboard')
  }

  handlereadNotification = (e, ele, id) => {
    let data = { notificationId: id }

    this.props.history.push('/notifications')

    delivaApiService('readNotification', data)
  }
  handlePagination = (e) => {
    this.setState(
      {
        pageNumber: this.state.pageNumber + 1,
      },
      () => {
        this.getNotifications(getLocalStorage('id'))
      }
    )
  }
  getKeyByValue = (object, value) => {
    return removedUnderscore(
      Object.keys(object).find((key) => object[key] === value)
    )
  }
  render() {
    return (
      <Row noGutters className="topbarWrapper">
        <Col md={3} lg={2} className="logo">
          <span
            className="pointer"
            onClick={() => this.props.history.push('/dashboard')}
          >
            <Image src={logo} alt="life Savor" />
          </span>
        </Col>
        <Col md={9} lg={10} className="boxTyp3 bdr-B box-shadow">
          <div className="topbar">
            <Row noGutters className=" align-items-center">
              <Col md={6} lg={4} className="">
                {/* <FormControl
                  type="text"
                  placeholder="Search here"
                  className="searchTxt"
                />
                <span className="icon-search"></span> */}
              </Col>
              <Col md={6} lg={8} className="text-right">
                <div className="line-ht">
                  <Dropdown className="notification-sections">
                    <Dropdown.Toggle id="notification-dropdown">
                      <span className="bellOuterBox">
                        <span className="fs20 icon-notification dark-grey"></span>
                        <span className="notificationCount">
                          {this.state.notification[0]?.notificationResponse
                            ?.length || 0}
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    {this.state.notification?.length > 0 ? (
                      <Dropdown.Menu>
                        <Dropdown.Item className="dropdown-head">
                          Notifications
                        </Dropdown.Item>
                        {/* CONSTANTS.ROLES[ele.notifictionAction] */}
                        <ul className="notification-list">
                          {this.state.notification[0].notificationResponse?.map(
                            (ele, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  className="active"
                                  onClick={(e) =>
                                    this.handlereadNotification(
                                      e,
                                      ele,
                                      ele.notificationId
                                    )
                                  }
                                >
                                  <span class="time-label fs15 ">
                                    {timeSince(ele.dateTime)}
                                  </span>
                                  <span
                                    className={`dot-cicles ${
                                      ele.read ? 'dot-grays' : 'dot-yellow'
                                    }`}
                                  ></span>
                                  <h6>
                                    {this.getKeyByValue(
                                      CONSTANTS.ROLES,
                                      ele.roleId
                                    )}
                                  </h6>
                                  <h6 className="color-grey">
                                    {ele.notification}
                                  </h6>
                                </Dropdown.Item>
                              )
                            }
                          )}
                        </ul>
                        {this.state.notificationLength > 5 ? (
                          <li
                            className="dropdown-footer pointer text-uppercase"
                            onClick={(e) => this.handlePagination(e)}
                          >
                            see all
                          </li>
                        ) : null}
                      </Dropdown.Menu>
                    ) : null}
                  </Dropdown>
                  <a
                    onClick={() => this.props.history.push('/settings/')}
                    className="fs20 icon-settings pointer ml-3 mr-3"
                  ></a>

                  <Dropdown className="headerDropdown2">
                    <Dropdown.Toggle variant="default" className="pl-1 pr-1">
                      <Image src={ProfileImg} alt="life Savor" height="55px" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.handleLogout()
                        }}
                      >
                        <span className="icon-logout"></span> Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Header
