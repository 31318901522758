import React, { Component } from "react";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col, Image, FormControl, Button, Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import userblock1 from "../../../assets/images/userblock1.svg";
import DownArrow from "../../../assets/images/DownArrow.svg";
import Form from "react-bootstrap/Form";
// import Unblock from "../../../assets/images/Unblock.svg";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage, range } from "../../../common/helpers/Utils";
import { withRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';


class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: "",
      keyword: "",
      search: "",
      totalOrders: [],
    };
  }

  componentDidMount() {
    console.log(this.props);
    console.log("customer");
    // alert('customer');

    if (getLocalStorage("adminInfo")) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    } else {
      this.props.history.push("/login");
    }
  }
  update() {
    this.getList(1, this.state.records, "desc", "eventName", "");
  }

  onChangeItemPerPage(e) {
    console.log(e);
    console.log(e.target);

    if (e.target.value && e.target.value > 0) {
      this.getList(
        1,
        e.target.value,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    } else {
      this.setState({
        records: "",
      });
    }
  }
  onChangeSearch(e) {
    console.log(e.target.value);
    if (e.target.value.length > 3) {
      this.getList(
        1,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        e.target.value
      );
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
      if (e.target.value.length == 0) {
        this.getList(
          1,
          this.state.records,
          this.state.sort,
          this.state.keyword,
          e.target.value
        );
      }
    }
  }
  handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.getList(
        1,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    }
  };
  onChangeSort(keyword, sort) {
    if (this.state.keyword == keyword) {
      this.getList(
        this.state.pageno,
        this.state.records,
        sort,
        keyword,
        this.state.search
      );
    } else {
      this.getList(
        // this.state.pageno,
        1, //pagenodoubt
        this.state.records,
        "desc",
        keyword,
        this.state.search
      );
    }
  }
  getList(pageno, records, sort, keyword, search) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sort: sort,
        keyword: keyword,
        search: search,
      },
      () => {
        console.log("showLoader", this.state.showLoader);
        this.setLoader(true);
      }
    );
    delivaApiService("getAllOrders", {
      id: getLocalStorage("id"),
      upperLimit: records,
      lowerLimit: pageno,
      // sort: sort,
      // keyword: keyword,
      // search: search,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.resourceData.totalOrdersCount);
          console.log(response.data.resourceData);
          console.log(response.data.resourceData.totalOrders);
          console.log(response.data.resourceData.totalOrdersCount);

          this.setState(
            {
              totalOrders: response.data.resourceData.totalOrders,
              totalOrdersCount: response.data.resourceData.totalOrdersCount,
            },
            () => {
              console.log(this.state.totalOrders);
              this.setLoader(false);
            }
          );
        } else {
          this.setLoader(false);
        }
      })
      .catch(
        this.setState({
          // showLoader: false,
        })
      );
  }
  getPager(total) {
    let startPage = this.state.startPage;
    let endPage = this.state.endPage;
    let totalPage = Math.ceil(total / this.state.records);
    console.log("totalPage", totalPage);
    let pageno = this.state.pageno;

    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (pageno <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = pageno - 2;
        endPage = pageno + 2;
      }
    }
    let startIndex = (pageno - 1) * this.state.records;
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1);

    // create an array of pages to ng-repeat in the pager control
    let pageArray;
    if (startPage == endPage) {
      console.log("startPage, endPage", startPage, endPage);

      pageArray = [1];
    } else {
      pageArray = range(startPage, endPage);
      console.log("startPage, endPage", startPage, endPage);
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    });
  }

  onChangePage(page) {
    console.log(page);
    console.log(this.state.pageno);
    if (page == this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    }
  }
  blockUnblock() {
    this.setLoader(true);
    let data = {
      // block: this.state.blockFlag,
      userId: this.state.blockId,
    };
    delivaApiService("blockUnblockCustomer", data).then((response) => {
      console.log(response);
      this.handleInviteVendors("");
      this.setLoader(false);
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    });
  }
  setBlockData(id, flag) {
    this.setState(
      {
        blockId: id,
        blockFlag: flag,
      },
      () => {
        this.props.handleBlockStatus(flag);
      }
    );
  }

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };
  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    });
  };
  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  


  render() {
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors("", "")}
          centered
        >
          {/* <Modal.Header closeButton className="border-0">
                
                </Modal.Header> */}
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="orange-text mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{" "}
                {this.state.blockedStatus == true ? "unblock" : "block"} this
                user?{" "}
                {this.state.blockedStatus == false
                  ? "You can not view this profile once blocked."
                  : ""}
              </p>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus == true ? "Unblock" : "Block"}
              </button>
              <button
                className="cancel-btn"
                onClick={() => this.handleInviteVendors("")}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 fs22 line-ht color-33">
                      TERMS & CONDITIONS{" "}
                      </h3>
                      
                    </Col>
                    
                  </Row>
                    <Row className="mb-3">
                        <Col md={12} lg={12} className="">
                            <div className="Terms-wrap">
                                
                            <Accordion defaultActiveKey="0" className="tc-wrap">
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="w-100 text-left color-black">
                                    Lorem dummy content <Image src={DownArrow} className="img-fluid user-block-img float-right" />
                                    </Accordion.Toggle>
                                    
                                    </Card.Header>
                                    
                                    <Accordion.Collapse eventKey="0">
                                    
                                    <Card.Body>
                                      lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className="w-100 text-left color-black">
                                    Lorem dummy content <Image src={DownArrow} className="img-fluid user-block-img float-right" />
                                    </Accordion.Toggle>
                                    
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2" className="w-100 text-left color-black">
                                    Lorem dummy content <Image src={DownArrow} className="img-fluid user-block-img float-right" />
                                    </Accordion.Toggle>
                                    
                                    </Card.Header>
                                    
                                    <Accordion.Collapse eventKey="2">
                                    
                                    <Card.Body>
                                      lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3" className="w-100 text-left color-black">
                                    Lorem dummy content <Image src={DownArrow} className="img-fluid user-block-img float-right" />
                                    </Accordion.Toggle>
                                    
                                    </Card.Header>
                                    
                                    <Accordion.Collapse eventKey="3">
                                    
                                    <Card.Body>
                                      lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4" className="w-100 text-left color-black">
                                    Lorem dummy content <Image src={DownArrow} className="img-fluid user-block-img float-right" />
                                    </Accordion.Toggle>
                                    
                                    </Card.Header>
                                    
                                    <Accordion.Collapse eventKey="4">
                                    
                                    <Card.Body>
                                      lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="5" className="w-100 text-left color-black">
                                    Lorem dummy content <Image src={DownArrow} className="img-fluid user-block-img float-right" />
                                    </Accordion.Toggle>
                                    
                                    </Card.Header>
                                    
                                    <Accordion.Collapse eventKey="5">
                                    
                                    <Card.Body>
                                      lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            </div>
                        </Col>
                        
                    </Row>
                    
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default TermsConditions;
// export default withRouter(TotalOrderTable);
