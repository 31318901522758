import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import userblock1 from '../../../assets/images/userblock1.svg'
import Form from 'react-bootstrap/Form'
import delivaApiService from '../../../common/services/apiService'
import {
  getLocalStorage,
  showErrorToast,
  showSuccessToast,
} from '../../../common/helpers/Utils'
import validateInput from '../../../common/validations/validationCreateAdmin'
import Loader from '../../common/Loader'
import CONSTANTS from '../../../common/helpers/Constants'
class CreateAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adminData: {},
      deleteUserDialog: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: '',
      keyword: '',
      search: '',
      totalOrders: [],
      name: '',
      email: '',
      mobile: '',
      roleId: '',
      city: '',
      country: '',
      errors: {},
      active: true,
      adminRoles: [],
      showLoader: false,
    }
  }

  componentDidMount() {
    this.getAllAdminRole()
    if (this.props.match.params.userId) {
      this.getAdminDetail()
    }
  }

  getAllAdminRole() {
    delivaApiService('getAllAdminRole')
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState({ adminRoles: response.data.resourceData })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  deleteUser() {
    this.setState({ deleteUserDialog: false })
    this.setLoader(true)
    delivaApiService('deleteAdmin', {
      userId: this.props.match.params.userId,
      loginUserId: getLocalStorage('id'),
    })
      .then((response) => {
        this.setLoader(false)
        if (response && response.data && response.data.resourceData) {
          showSuccessToast(response.data.responseMessage)
          this.props.history.push('/list')
        } else {
          showErrorToast(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handleDeleteUser() {
    this.setState({ deleteUserDialog: !this.state.deleteUserDialog })
  }

  handleActiveInactiveStatus(val) {
    this.setState({ active: val })
  }

  getAdminDetail = () => {
    delivaApiService('getAdminProfile', {
      userId: this.props.match.params.userId,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState({
            adminData: response.data.resourceData,
            name: response.data.resourceData.name,
            mobile: response.data.resourceData.mobile,
            country: response.data.resourceData.country,
            city: response.data.resourceData.city,
            email: response.data.resourceData.email,
            roleId: response.data.resourceData.roleId,
            active: response.data.resourceData.active,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  createAdminUser() {
    let data = {
      active: true,
      name: this.state.name?.trim() || '',
      mobile: this.state.mobile || '',
      email: this.state.email.toLowerCase()?.trim() || '',
      country: this.state.country?.trim() || '',
      city: this.state.city?.trim() || '',
      roleId: this.state.roleId || '',
    }
    if (this.isValid(data)) {
      this.setLoader(true)
      // data = { ...data, roleId: data.roleId == "admin" ? 1 : 0 };
      delivaApiService('createAdmin', data)
        .then((response) => {
          this.setLoader(false)
          if (response && response.data && response.data.resourceData) {
            this.setState(
              {
                name: '',
                mobile: '',
                email: '',
                city: '',
                country: '',
                roleId: '',
              },
              () => {
                this.props.history.push('/list')
              }
            )
          } else {
            showErrorToast(response.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  updateAdminUser() {
    let data = {
      active: this.state.active || '',
      userId: this.props.match.params.userId || '',
      name: this.state.name?.trim() || '',
      mobile: this.state.mobile || '',
      email: this.state.email?.toLowerCase()?.trim() || '',
      country: this.state.country?.trim() || '',
      city: this.state.city?.trim() || '',
      roleId: this.state.roleId || '',
    }
    if (this.isValid(data)) {
      this.setLoader(true)
      delivaApiService('updateAdminProfile', data)
        .then((response) => {
          this.setLoader(false)
          if (response && response?.data && response?.data?.resourceData) {
            this.setState(
              {
                name: '',
                mobile: 1,
                email: '',
                city: '',
                country: '',
                roleId: '',
              },
              () => {
                this.props.history.push('/list')
              }
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  handleSubmit = () => {
    if (this.props.match.params.userId) {
      this.updateAdminUser()
    } else {
      this.createAdminUser()
    }
  }

  isValid(data) {
    const { errors, isValid } = validateInput(data)
    if (!isValid) {
      this.setState({ errors })
    }
    return isValid
  }

  handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'mobile') {
      // Regular expression to allow only numbers
      const numbersOnly = /^[0-9]*$/

      // Check if the input value contains only numbers or is empty
      if (numbersOnly.test(value) || value === '') {
        this.setState({
          [name]: value,
          errors: {
            ...this.state.errors,
            [name]: false, // Clear any previous errors
          },
        })
      } else {
        // Display an error for non-numeric input
        this.setState({
          errors: {
            ...this.state.errors,
            [name]: true,
          },
        })
      }
    } else {
      // For other fields, allow any input
      this.setState({
        [name]: value,
      })
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  render() {
    const { name, email, mobile, country, city, roleId, errors } = this.state
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.deleteUserDialog}
          onHide={() => this.handleDeleteUser()}
          centered
        >
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="orange-text mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to delete this user?{' '}
                {this.state.blockedStatus == false
                  ? 'You can not undo this once deleted.'
                  : ''}
              </p>
              <button onClick={() => this.deleteUser()} className="b1-btn">
                Delete
              </button>
              <button
                className="cancel-btn"
                onClick={() => this.handleDeleteUser()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-2 fs22 color-33">
                        {!this.props.match.params.userId
                          ? 'Create Admin '
                          : this.props.match.params.type == 'v'
                          ? 'View Admin '
                          : 'Edit Admin '}
                      </h3>
                      <h5 className="font-weight-normal mb-0 fs18 color-grey">
                        <span
                          onClick={() => this.props.history.push(`/list`)}
                          className="pointer"
                        >
                          Manage Admin
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        {!this.props.match.params.userId
                          ? 'Create Admin '
                          : this.props.match.params.type == 'v'
                          ? 'View Admin '
                          : 'Edit Admin '}
                        {/* <span className="icon-next mx-2 Fs12"></span>{" "} */}
                      </h5>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} lg={12} className="">
                      <div className="admin-wrap">
                        <div className="admin-head">
                          <p className="mb-0">Personal Info</p>
                        </div>
                        <div className="admin-body">
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    disabled={
                                      this.props.match.params.type == 'v'
                                    }
                                    className="login-input"
                                    error={errors.name ? true : false}
                                    onKeyPress={this.handleEnter}
                                    onChange={this.handleChange}
                                    value={name}
                                  />
                                  <div className="error position-relative mt-2 text-danger">
                                    {errors.name}
                                  </div>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control
                                    type="text"
                                    placeholder="Email Id"
                                    name="email"
                                    disabled={this.props.match.params.userId}
                                    className="login-input"
                                    error={errors.email ? true : false}
                                    onKeyPress={this.handleEnter}
                                    onChange={this.handleChange}
                                    value={email}
                                  />
                                  <div className="error position-relative mt-2 text-danger">
                                    {errors.email}
                                  </div>
                                </Form.Group>
                              </Form>
                            </div>
                            <div className="col-md-6 position-relative">
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control
                                    type="tel"
                                    placeholder="Mobile No."
                                    name="mobile"
                                    className="login-input"
                                    disabled={this.props.match.params.userId}
                                    error={errors.mobile ? true : false}
                                    onKeyPress={this.handleEnter}
                                    onChange={this.handleChange}
                                    value={mobile}
                                    maxLength={CONSTANTS.MAXLENGTH.MOBILE}
                                  />
                                  <div className="error position-relative mt-2 text-danger">
                                    {errors.mobile}
                                  </div>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div className="admin-head">
                          <p className="mb-0">Additional Info</p>
                        </div>
                        <div className="admin-body">
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control
                                    type="text"
                                    placeholder="Country"
                                    name="country"
                                    className="login-input"
                                    disabled={
                                      this.props.match.params.type == 'v'
                                    }
                                    error={errors.country ? true : false}
                                    onKeyPress={this.handleEnter}
                                    onChange={this.handleChange}
                                    value={country}
                                  />
                                  <div className="error position-relative mt-2 text-danger">
                                    {errors.country}
                                  </div>
                                </Form.Group>
                              </Form>
                            </div>
                            <div className="col-md-6 position-relative">
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control
                                    type="text"
                                    placeholder="City"
                                    name="city"
                                    disabled={
                                      this.props.match.params.type == 'v'
                                    }
                                    className="login-input"
                                    error={errors.city ? true : false}
                                    onKeyPress={this.handleEnter}
                                    onChange={this.handleChange}
                                    value={city}
                                  />
                                  <div className="error position-relative mt-2 text-danger">
                                    {errors.city}
                                  </div>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <Form>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control
                                    className="login-input"
                                    as="select"
                                    custom
                                    name="roleId"
                                    error={errors.roleId ? true : false}
                                    onKeyPress={this.handleEnter}
                                    onChange={this.handleChange}
                                    value={parseInt(roleId)}
                                    disabled={
                                      this.props.match.params.type == 'v'
                                    }
                                  >
                                    <option value={''}>Role</option>
                                    {this.state.adminRoles.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.roleId}
                                            key={index}
                                          >
                                            {item.userRole}
                                          </option>
                                        )
                                      }
                                    )}
                                  </Form.Control>
                                  <div className="error position-relative mt-2 text-danger">
                                    {errors.role}
                                  </div>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>

                          {!this.props.match.params.userId ? (
                            <div>
                              <button
                                className="b1-btn"
                                onClick={() => this.handleSubmit()}
                              >
                                Create
                              </button>
                              <button
                                className="cancel-btn bg-none"
                                onClick={() => this.props.history.push('/list')}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : this.props.match.params.type == 'e' ? (
                            <div>
                              <p className="mb-0">Status Info</p>
                              <div className="status-btn mt-2 mb-4">
                                <button
                                  className={
                                    this.state.active
                                      ? 'active-btn'
                                      : 'inactive-btn'
                                  }
                                  onClick={() =>
                                    this.handleActiveInactiveStatus(true)
                                  }
                                >
                                  Active
                                </button>
                                <button
                                  className={
                                    !this.state.active
                                      ? 'active-btn'
                                      : 'inactive-btn'
                                  }
                                  onClick={() =>
                                    this.handleActiveInactiveStatus(false)
                                  }
                                >
                                  Inactive
                                </button>
                              </div>
                              <button
                                className="b1-btn"
                                onClick={() => this.handleSubmit()}
                              >
                                Save
                              </button>
                              <button
                                className="cancel-btn bg-none"
                                onClick={() => this.handleDeleteUser()}
                              >
                                Delete
                              </button>
                              <button
                                className="cancel-btn bg-none"
                                onClick={() => this.props.history.push('/list')}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    )
  }
}
export default CreateAdmin
// export default withRouter(TotalOrderTable);
