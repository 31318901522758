import { LOGIN, LOGOUT } from "../types";
import {
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
} from "../../helpers/Utils";
import CONSTANTS from "../../helpers/Constants";

import _ from "lodash";

const INITIAL_STATE = {
  organizerData: getLocalStorage("organizerInfo") || {},
  vendorData: getLocalStorage("vendorInfo") || {},
  adminData: getLocalStorage("adminInfo") || {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      if (action.payload && action.payload.data) {
        const userInfo = action.payload.data.access_token;
        const id = action.payload.data.userid;
        // let userType = userInfo.user_type;
        // if (userType === CONSTANTS.ROLES.ORGANIZER) {
        //   state.organizerInfo = userInfo;
        //   setLocalStorage('organizerInfo', userInfo);
        // } else if (userType === CONSTANTS.ROLES.VENDOR) {
        //   state.vendorInfo = userInfo;
        //   setLocalStorage('vendorInfo', userInfo);
        // }  else if (userType === CONSTANTS.ROLES.ADMIN) {
        state.adminInfo = userInfo;
        if (action.payload.status == 200) {
          setLocalStorage("adminInfo", userInfo);
          setLocalStorage("id", id);
        }
        // }
      }
      return { ...state };

    case LOGOUT:
      state.organizerInfo = {};
      state.vendorInfo = {};
      state.adminInfo = {};
      clearLocalStorage();

      return { ...state };

    default:
      return state;
  }
};
