import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorage } from "../common/helpers/Utils";

const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getLocalStorage("adminInfo") ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

export default PrivateRouteAdmin;
