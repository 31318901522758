import React, { Component } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import RejectIcon from '../../../assets/images/RejectIcon.svg'
import approved from '../../../assets/images/approved.svg'
import delivaApiService from '../../../common/services/apiService'
import validateInput from '../../../common/validations/validateDetailsPA'
import { showErrorToast } from '../../../common/helpers/Utils'

class ProcessingAgentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details: {},
      showRejectModal: false,
      passportVerification: false,
      bankVerification: false,
      timeVerification: false,
      idproofVerification: false,
      errors: {},
      rejectionReason: 'Other',
      successModal: false,
      details: {},
      showLoader: false,
    }
  }

  handleReject = (userId) => {
    this.setState({
      showRejectModal: !this.state.showRejectModal,
      userId,
    })
  }

  componentDidMount() {
    if (this.props && this.props.location.state.item) {
      this.getProcessingAgentDetail()
    }
  }

  approveDA = (userId, name) => {
    if (this.isValid()) {
      this.setState({
        showLoader: true,
        daName: name,
        userId: userId,
      })
      delivaApiService('approveDA', { userId })
        .then((response) => {
          this.setState({ showLoader: false, successModal: true })
          if (response.data.status == 200) {
            this.getProcessingAgentDetail(userId)
            this.setState({
              errors: {},
            })
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          })
        })
    }
  }

  approvalSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
    })
  }

  onChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  getProcessingAgentDetail() {
    this.setLoader(true)
    delivaApiService('getProcessingAgentDetail', {
      id: this.props.location.state.item.userId,
    })
      .then((response) => {
        if (response && response.data) {
          this.setState({ details: response.data.resourceData })
        } else {
          this.setLoader(false)
        }
      })
      .catch((err) => {
        this.setLoader(false)
      })
  }

  operatingTime = (week) => {
    const { details } = this.state
    var t_1 = 'Closed'

    if (details?.operationalHours && details?.operationalHours.days) {
      const dayInfo = details.operationalHours.days.find(
        (item) => item.dayName === week
      )

      if (dayInfo) {
        if (dayInfo.open) {
          t_1 = `${dayInfo.startTime} - ${dayInfo.endTime}`
        }
      }
    }

    return t_1
  }

  rating = (rating) => {
    let drawRating = ''
    for (var i = 1; i <= 5; i++) {
      if (i <= rating) {
        drawRating += '<span className="icon-shapes-and-symbols"></span>'
      } else {
        drawRating += '<span className="icon-star"></span>'
      }
    }
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if (!isValid) {
      this.setState({ errors })
    }
    console.log(isValid)
    return isValid
  }

  submitReject = () => {
    if (
      this.state.rejectionReason &&
      this.state.description &&
      this.state.userId
    ) {
      let data = {
        reasonForRejection: this.state.rejectionReason,
        rejectionDetail: this.state.description,
        userId: this.state.userId,
      }
      delivaApiService('rejectDA', data)
        .then((response) => {
          this.setState({ showLoader: false })
          if (response.data.status == 200) {
            this.setState({
              showRejectModal: !this.state.showRejectModal,
            })
            this.props.history.push('/verification/4')
            this.getProcessingAgentDetail(data.userId)
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          })
        })
    } else {
      showErrorToast('Please Write Rejection Details')
    }
  }
  render() {
    const { details, errors } = this.state
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal fs22 mb-0 line-ht color-33">
                        Processing agent
                      </h3>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <button
                        className={`${
                          details?.rejected ? 'red-text-btn' : 'yellow-text-btn'
                        }  fs20`}
                      >
                        {details?.rejected
                          ? 'Rejected'
                          : details.active
                          ? 'In-progress'
                          : 'Pending'}
                      </button>
                    </Col>
                  </Row>
                  <div className="cust-details-wrap mb-4 delivery-agent-detail-bg">
                    <Row noGutters className="">
                      <Col md={12} lg={12} className="">
                        <h5 className=" ">Personal details</h5>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={3} className="">
                        <div className="pr-wrap">
                          <h5 className="font-weight-normal color-grey fs18">
                            Name
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.bankingDetails
                              ? details.bankingDetails.fullName
                              : ''}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Agent Id
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            #{details.userId}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Date of Registration
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {moment(
                              this.props.location?.state?.item?.createdDate
                            ).format('DD MMM YYYY')}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Mobile No.
                          </h5>
                          {details.countryCode && details.mobile ? (
                            <h5 className="fs18 font-weight-normal">
                              +{details.countryCode}-{details.mobile}
                            </h5>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>

                      <Col md={12} lg={12} className="px-5">
                        <hr className="yellow-hr" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={3}>
                        <h5 className="font-weight-normal color-grey fs18">
                          Email
                        </h5>
                        <h4 className="fs18 font-weight-normal">
                          {details.email}
                        </h4>
                      </Col>
                      <Col md={3} lg={3}>
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Name
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.businessName || ''}
                          </h5>
                        </div>
                      </Col>
                      <Col md={3} lg={3}>
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Business Type
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.businessName || ''}
                          </h5>
                        </div>
                      </Col>

                      <Col md={3} lg={3}>
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Business Reg No
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.businessRegistrationNumber || ''}
                          </h5>
                        </div>
                      </Col>
                      <Col md={12} lg={12} className="px-5">
                        <hr className="yellow-hr" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6}>
                        <h5 className="font-weight-normal color-grey fs18">
                          Address
                        </h5>
                        <h5 className="fs18 font-weight-normal">
                          {details.bussinessAddress &&
                          details.bussinessAddress.aptNumber
                            ? details.bussinessAddress.aptNumber + ' '
                            : ''}

                          {details.bussinessAddress &&
                          details.bussinessAddress.address
                            ? details.bussinessAddress.address + ', '
                            : ''}

                          {details.bussinessAddress &&
                          details.bussinessAddress.city
                            ? details.bussinessAddress.city + ', '
                            : ''}

                          {details.bussinessAddress &&
                          details.bussinessAddress.state
                            ? details.bussinessAddress.state + ', '
                            : ''}

                          {details.bussinessAddress &&
                          details.bussinessAddress.country
                            ? details.bussinessAddress.country
                            : ''}
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-layout-update">
                    <Row className="mb-3">
                      {/* Passport details */}
                      <Col md={6} lg={6} className="">
                        <div className="details-wrap optional-hrs">
                          <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                            Operational hours
                          </h4>
                          <hr className="mt-2" />
                          <div className="row m-0">
                            <div className="col-sm-6">
                              <ul className="hrs-list">
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">Monday</span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Monday')}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Tuesday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Tuesday')}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Wednesday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Wednesday')}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Thursday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Thursday')}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="col-sm-6">
                              <ul className="hrs-list">
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Friday{' '}
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Friday')}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Saturday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Saturday')}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">Sunday</span>
                                    </div>
                                    <div className="col-sm-7 text-right">
                                      {this.operatingTime('Sunday')}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <hr />
                            {details.active ? (
                              <div className="deliva-radio text-center">
                                <input
                                  type="checkbox"
                                  id="test1"
                                  name="timeVerification"
                                  checked
                                />
                                <label htmlFor="test2">Verified</label>
                              </div>
                            ) : (
                              <div className="deliva-radio text-center">
                                <input
                                  type="checkbox"
                                  id="test1"
                                  name="timeVerification"
                                  value={this.state.timeVerification}
                                  onChange={this.onChange}
                                />
                                <label htmlFor="test1">Verify</label>
                                <div class="error text-danger">
                                  {errors.timeVerification}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      {/* Banking details */}
                      {details.bankingDetails ? (
                        <Col md={6} lg={6} className="">
                          <div className="details-wrap">
                            <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                              Banking Details
                            </h4>
                            <hr className="mt-2" />
                            <div className="row">
                              <div className="col-sm-6">
                                <h4 className="pl-4 font-weight-normal color-grey fs18">
                                  Full Name
                                </h4>
                                <p className="fs20 pl-4 ">
                                  {details.bankingDetails.fullName || ''}
                                </p>
                                <h4 className="pl-4 font-weight-normal color-grey fs18 mt-4">
                                  Bank
                                </h4>
                                <p className="fs20 pl-4 ">
                                  {details.bankingDetails.bankName || ''}{' '}
                                  {details.bankingDetails.bankLocation || ''}
                                </p>
                              </div>
                              <div className="col-sm-6">
                                <h4 className="font-weight-normal color-grey fs18">
                                  Account Number
                                </h4>
                                <p className="fs20 ">
                                  {details.bankingDetails.accountNumber || ''}
                                </p>
                                <h4 className="font-weight-normal color-grey fs18 mt-4">
                                  ABA Number
                                </h4>
                                <p className="fs20 ">
                                  {details.bankingDetails.abaNumber || ''}
                                </p>
                              </div>
                              <div className="col-sm-12">
                                <hr />
                                {details.active ? (
                                  <div className="deliva-radio text-center">
                                    <input
                                      type="checkbox"
                                      id="test2"
                                      name="bankVerification"
                                      checked
                                    />
                                    <label htmlFor="test2">Verified</label>
                                  </div>
                                ) : (
                                  <div className="deliva-radio text-center">
                                    <input
                                      type="checkbox"
                                      id="test2"
                                      name="bankVerification"
                                      value={this.state.bankVerification}
                                      onChange={this.onChange}
                                    />
                                    <label htmlFor="test2">Verify</label>
                                    <div class="error text-danger">
                                      {errors.bankVerification}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                    {!details.active ? (
                      <Row>
                        <Col md={12} lg={12}>
                          <div className="text-center mt-5">
                            {!details?.rejected && (
                              <button
                                className="reject-btn"
                                onClick={this.handleReject.bind(
                                  this,
                                  details.userId
                                )}
                              >
                                Reject
                              </button>
                            )}
                            <button
                              className="approve-btn"
                              onClick={this.approveDA.bind(
                                this,
                                details.userId,
                                details.name
                              )}
                            >
                              Approve
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Modal
          show={this.state.showRejectModal}
          onHide={this.handleReject.bind(this, '')}
          centered
          className="deliva-popup"
        >
          <Modal.Body>
            <div className="modal-content-wrap ">
              <div className="text-center">
                <Image
                  src={RejectIcon}
                  className="img-fluid reject-img pointer"
                  onClick={this.handleReject.bind(this, '')}
                />
                <h4 className="orange-text mt-3 fs-22">
                  Are you sure to Reject this request ?
                </h4>
                <p className="color-head">
                  Please add/choose the reason of rejection{' '}
                </p>
              </div>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="color-head">
                  Choose the Reason
                </Form.Label>
                <Form.Control
                  as="select"
                  name="rejectionReason"
                  onChange={this.onChange}
                  value={this.state.rejectionReason}
                  className="py-0"
                >
                  <option value="Other">Other</option>
                  <option value="Invalid Passport Details">
                    Invalid Passport Details
                  </option>
                  <option value="Invalid Banking Details">
                    Invalid Banking Details
                  </option>
                  <option value="Invalid ID Proof">Invalid ID Proof</option>
                  <option value="Criminal Background">
                    Criminal Background
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="color-head">
                  Rejection details
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="description"
                  onChange={this.onChange}
                  maxlength="100"
                />
                <span className="min-char">Min 100 chars</span>
              </Form.Group>
              <div className="text-center">
                <button
                  className="b1-btn"
                  onClick={this.submitReject.bind(this)}
                >
                  Yes
                </button>
                <button
                  className="cancel-btn"
                  onClick={this.handleReject.bind(this, '')}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.successModal}
          onHide={() => this.approvalSuccessModal(this)}
          centered
        >
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={approved} className="img-fluid user-block-img" />
              <h4 className="lightGreenTex mt-3">Approved</h4>
              <p className="color-grey">
                {this.state.daName} #{this.state.userId} detail are approved
              </p>

              <button
                className="cancel-btn ml-0 blueBg whiteText"
                onClick={() =>
                  // this.approvalSuccessModal(this)
                  this.props.history.push('/verification/4')
                }
              >
                Back
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default ProcessingAgentDetails
