import React, { Component } from 'react';
import { Row, Col, Image, FormControl } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import userblock1 from '../../../assets/images/userblock1.svg';

import Sidebar from '../commonAdmin/Sidebar';
import Header from '../commonAdmin/Header';
import Footer from '../commonAdmin/Footer';
import delivaApiService from '../../../common/services/apiService';
import { getLocalStorage, range } from '../../../common/helpers/Utils';
import { NavLink } from 'react-router-dom';
import Loader from '../../common/Loader';
import CONSTANTS from '../../../common/helpers/Constants';

class AdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sortBy: '',
      sortOrder: '',
      search: '',
      adminList: [],
    };
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    } else {
      this.props.history.push('/login');
    }
  }
  update() {
    this.getList(
      1,
      this.state.records,
      this.state.sortBy,
      this.state.sortOrder
    );
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(1, e.target.value, this.state.sortBy, this.state.sortOrder);
    } else {
      this.setState({
        records: '',
      });
    }
  }

  onChangeSort(val) {
    this.setState({ sortOrder: this.state.sortOrder === 'desc' ? '' : 'desc' });
    if (this.state.sortOrder === 'desc') {
      this.getList(this.state.pageno, this.state.records, val, 'asc');
    } else {
      this.getList(this.state.pageno, this.state.records, val, 'desc');
    }
  }

  getList(pageno, records, sortBy, sortOrder) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sortBy: sortBy,
        sortOrder: sortOrder,
        adminList: [],
      },
      () => {
        this.setLoader(true);
      }
    );
    delivaApiService('getAllAdmin', {
      id: getLocalStorage('id'),
      records: records==0 ? CONSTANTS.SHOWRECORDS.NOOFRECORDS : records,
      pageNumber: pageno,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchByProfile: this.state.search,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.resourceData.count);
          this.setState(
            {
              adminList: response.data.resourceData.data,
              totalCount: response.data.resourceData.count,
            },
            () => {
              this.setLoader(false);
            }
          );
        } else {
          this.setState({
            adminList: [],
            totalCount: 0,
          })
          this.setLoader(false);
        }
      })
      .catch(
        this.setState({
          // showLoader: false,
        })
      );
  }
  getPager(total) {
    this.setState({records: this.state.records==0 ? CONSTANTS.SHOWRECORDS.NOOFRECORDS : this.state.records},()=>{
    let startPage = this.state.startPage;
    let endPage = this.state.endPage;
    let totalPage = Math.ceil(total / this.state.records);
    let pageno = this.state.pageno;

    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (pageno <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = pageno - 2;
        endPage = pageno + 2;
      }
    }
    let startIndex = (pageno - 1) * this.state.records;
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1);

    // create an array of pages to ng-repeat in the pager control
    let pageArray;
    if (startPage === endPage) {
      pageArray = [1];
    } else {
      pageArray = range(startPage, endPage);
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    });
  })
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      if (this.state.search === '' || this.state.search.trim() === '') {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        );
      }
    });
  };

  onChangePage(page) {
    if (page === this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    }
  };

  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  handleSearch() {
    if (this.state.search.trim() !== '') {
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    }
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors('', '')}
          centered
        >
          {/* <Modal.Header closeButton className="border-0">
                
                </Modal.Header> */}
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="orange-text mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{' '}
                {this.state.blockedStatus === true ? 'unblock' : 'block'} this
                user?{' '}
                {this.state.blockedStatus === false
                  ? 'You can not view this profile once blocked.'
                  : ''}
              </p>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus === true ? 'Unblock' : 'Block'}
              </button>
              <button
                className="cancel-btn"
                onClick={() => this.handleInviteVendors('')}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={4} lg={6} className="">
                      <h3 className="font-weight-normal mb-2 color-33">
                        Manage Admin{' '}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                    </Col>

                    <Col md={4} lg={4} className="text-right"> 
                      <div className="mxw-90">
                      <FormControl
                        type="text"
                        placeholder="Search by Name, Admin ID, Location"
                        className="searchTxt mb-3"
                        onChange={(e) => this.handleChangeInput(e)}
                        onKeyDown={this.handleEnter.bind(this)}
                      />
                      <span
                        className="icon-search"
                        onClick={() => this.handleSearch()}
                      ></span>
                      </div>
                    </Col>

                    <Col md={4} lg={2} className="text-right">
                      <NavLink to="/createadmin">
                        <button className="bor-btn bor-blues mr-3"> 
                          <span className="fs20">+</span> Create Admin
                        </button>
                      </NavLink>
                    </Col>
                  </Row>

                  {/* <Row noGutters>
                    <Col md={6} lg={7}></Col>
                    <Col md={6} lg={5} className="text-right">
                      <FormControl
                        type="text"
                        placeholder="Search by Name, Admin ID, Location"
                        className="searchTxt mb-3"
                        onChange={(e) => this.handleChangeInput(e)}
                        onKeyDown={this.handleEnter.bind(this)}
                      />
                      <span
                        className="icon-search"
                        onClick={() => this.handleSearch()}
                      ></span>
                    </Col>
                  </Row> */}

                  <div className="row">
                    <div className="col-sm-12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Admin Name{' '}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort('admin')}
                              ></a>
                            </th>
                            <th>
                              Admin ID{' '}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.onChangeSort('registrationId')
                                }
                              ></a>
                            </th>
                            <th>
                              Location{' '}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort('location')}
                              ></a>
                            </th>
                            <th>
                              Status{' '}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort('status')}
                              ></a>
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {this.state.adminList.length==0 ? <tr><td colSpan={7} style={{ textAlign: 'center' }}>No Records Available</td></tr>  :
                            this.state.adminList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.name}</td>
                                  <td>{item.userId ? item.userId : '-'}</td>
                                  <td>{item.city}</td>
                                  <td className={`pl-4 ${item.active === true?'text-green':'text-red'}`}>
                                    <span className={`ab-dot ${item.active === true?'green':'red'}`}></span>
                                    {item.active === true
                                      ? 'Active'
                                      : 'Inactive'}
                                  </td>
                                  <td>
                                    <NavLink
                                      to={`/viewadmin/v/${item.userId}`}
                                    >
                                      <span className="icon-eye"></span> View
                                    </NavLink>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <NavLink
                                      to={`/editadmin/e/${item.userId}`}
                                    >
                                        <span className="icon-edit-ico"></span>{' '}
                                        Edit
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {this.state.adminList.length ? 
                        <nav aria-label="Page navigation">
                        <ul className="pagination pg-blue deliva-pagination justify-content-end pr-180p">
                          <li className="page-item">
                            <button
                              className="page-link rotate-180 control-btn"
                              aria-label="Previous"
                              onClick={() =>
                                this.onChangePage(this.state.pageno - 1)
                              }
                              disabled={
                                this.state.pageno === 1 ||
                                this.state.totalPage === 0
                              }
                            >
                              <span className="icon-next"></span>
                              <span
                                //className="prevNext"
                                className={`sr-only ${
                                  this.state.pageno === 1 ||
                                  this.state.totalPage === 0
                                    ? ''
                                    : 'active'
                                }`}
                              >
                                Previous
                              </span>
                            </button>
                          </li>
                          {this.state.totalPage > 0 &&
                            this.state.pageArray.map((page, ind) => {
                              return (
                                <li key={ind} className="page-item">
                                  <a
                                    className={`page-link ${
                                      this.state.pageno === page ? 'active' : ''
                                    }`}
                                    onClick={() => this.onChangePage(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              );
                            })}

                          <li className="page-item">
                            <button
                              className="page-link control-btn"
                              aria-label="Next"
                              onClick={() =>
                                this.onChangePage(this.state.pageno + 1)
                              }
                              disabled={
                                this.state.pageno === this.state.totalPage ||
                                this.state.totalPage === 0
                              }
                            >
                              <span className="icon-next"></span>
                              <span
                                className={`sr-only ${
                                  this.state.pageno === this.state.totalPage ||
                                  this.state.totalPage === 0
                                    ? ''
                                    : 'active'
                                }`}
                              >
                                Next
                              </span>
                            </button>
                          </li>
                          <li className="page-value">
                            Enter Per Page{' '}
                            <input
                              type="text"
                              placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                              name="records"
                              value={this.state.records}
                              onChange={(e) => this.onChangeItemPerPage(e)}
                            />
                          </li>
                        </ul>
                      </nav>
                      : null}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default AdminList;
// export default withRouter(AdminCustomerTable);
