import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Route
import PrivateRouteAdmin from './PrivateRouteAdmin'
import PublicRoute from './PublicRoute'

// admin
import Login from '../Component/admin/login/Login'
import Orders from '../Component/admin/dashboard/Orders'
import OrderType from '../Component/admin/dashboard/ordertype'
// import CanceledOrders from "../Component/admin/dashboard/CancelledOrders";
import DeliveredOrders from '../Component/admin/dashboard/delivered'
// import IntransitOrders from "../Component/admin/dashboard/IntransitOrders";
import AdminDashboard from '../Component/admin/dashboard/Dashboard'
import AdminCustomerTable from '../Component/admin/customer/CustomerTable'
import DeliveryAgentTable from '../Component/admin/DeliveryAgent/DeliveryAgentTable'
import DeliveryAgentProfile from '../Component/admin/DeliveryAgent/DeliveryAgentProfile'
import ProccessingCenters from '../Component/admin/processingAgent/ProccessingCenters'
import ResetPassword from '../Component/common/resetPassword/ResetPassword'
import AdminForgotPassword from '../Component/admin/forgotPassword/ForgotPassword'

import TableDemo from '../Component/admin/totalOrder/TableDemo'
import TotalOrderTable from '../Component/admin/totalOrder/TotalOrder'
import OpenDeliveryRequests from '../Component/admin/dashboard/openDeliveryRequests'
import OrderDetails from '../Component/admin/totalOrder/OrderDetails'
import StoreProfile from '../Component/admin/processingAgent/processingAgentDetails'
import Tableapp from '../Component/admin/processingAgent/table'
import DeliveryAgentTotalOrders from '../Component/admin/DeliveryAgent/DeliveryAgentTotalOrders'
import CustomersDetails from '../Component/admin/customer/CustomersDetails'
import OrdersDetails from '../Component/admin/dashboard/totalOrderDetails'
import VerificationDAPA from '../Component/admin/verification/verificationDA'
import BidTables from '../Component/admin/DeliveryAgent/BidTables'
import DeliveryAgentTripTable from '../Component/admin/DeliveryAgent/TripTable'
import DeliveryAgentDetails from '../Component/admin/DeliveryAgent/DeliveryAgentDetails'
import ProcessingAgentDetails from '../Component/admin/DeliveryAgent/ProcessingAgentDetails'

import TotalProcessingOrders from '../Component/admin/processingAgent/TotalProcessingOrders'
import AminProfile from '../Component/admin/DeliveryAgent/AminProfile'
import EditProfile from '../Component/admin/DeliveryAgent/EditProfile'
import Notifications from '../Component/admin/totalOrder/Notifications'
import Accounts from '../Component/admin/totalOrder/Accounts'
import PayoutSystem from '../Component/admin/totalOrder/PayoutSystem'
import Settings from '../Component/admin/totalOrder/Settings'
import CreateAdmin from '../Component/admin/totalOrder/CreateAdmin'
import AdminList from '../Component/admin/totalOrder/AdminList'
import TermsConditions from '../Component/admin/totalOrder/TermsConditions'
import Tnc from '../Component/admin/tnc/Tnc'
import EditAdmin from '../Component/admin/totalOrder/EditAdmin'
import PendingDAPA from '../Component/admin/dashboard/Pending'
import OrdersList from '../Component/admin/billing/OrdersList'
import TransferAmount from '../Component/admin/billing/TransferAmount'
import CommissionList from '../Component/admin/commission/CommissionList'
import AccountDelete from '../Component/admin/accountDelete/AccountDelete'

class DelivaRoute extends Component {
  render() {
    return (
      <>
        <ToastContainer
          autoClose={5000}
          className="custom-toaster-main-cls"
          toastClassName="custom-toaster-bg"
        />
        <Switch>
          <PublicRoute exact path="/" component={Login} />

          {/* <PrivateRouteAdmin path="/admin/orders/:name" component={Orders} /> */}
          <PrivateRouteAdmin path="/orders/:name" component={OrderType} />
          <PrivateRouteAdmin path="/notifications" component={Notifications} />
          <PrivateRouteAdmin path="/Accounts" component={Accounts} />
          <PrivateRouteAdmin path="/PayoutSystem" component={PayoutSystem} />
          <PrivateRouteAdmin path="/settings" component={Settings} />

          <PrivateRouteAdmin
            path="/:name/details/:id"
            component={OrdersDetails}
          />
          <PrivateRouteAdmin
            path="/verification/:id"
            component={VerificationDAPA}
          />

          <PrivateRouteAdmin path="/pending/:id" component={PendingDAPA} />

          <PrivateRouteAdmin
            path="/deliveryagent/:name/:id"
            component={BidTables}
          />
          <PrivateRouteAdmin
            path="/deliveryagentorders/totalorders/:id"
            component={DeliveryAgentTotalOrders}
          />
          {/* <PrivateRouteAdmin
            path="/admin/deliveryagentorders/totalorders/:name/:id"
            component={DeliveryAgentTripTable}
          /> */}

          <PrivateRouteAdmin
            path="/deliveryagenttrips/totaltrips/:id"
            component={DeliveryAgentTripTable}
          />

          <PrivateRouteAdmin path="/delivered" component={DeliveredOrders} />

          <PrivateRouteAdmin path="/dashboard" component={AdminDashboard} />
          <PrivateRouteAdmin path="/Tableapp" component={Tableapp} />
          <PrivateRouteAdmin
            path="/customerlist"
            component={AdminCustomerTable}
          />
          <PrivateRouteAdmin
            exact
            path="/customerDetails"
            component={CustomersDetails}
          />
          <PrivateRouteAdmin
            exact
            path="/customerDetails/:id"
            component={CustomersDetails}
          />
          <PrivateRouteAdmin
            path="/customerDetails"
            component={CustomersDetails}
          />
          <PrivateRouteAdmin
            path="/deliveryagentlist"
            component={DeliveryAgentTable}
          />

          <PrivateRouteAdmin
            path="/deliveryagentprofile"
            component={DeliveryAgentProfile}
          />
          <PrivateRouteAdmin path="/AminProfile" component={AminProfile} />
          <PrivateRouteAdmin path="/EditProfile" component={EditProfile} />
          <PrivateRouteAdmin
            path="/agent/verify"
            component={DeliveryAgentDetails}
          />
          <PrivateRouteAdmin
            path="/processingagent/verify"
            component={ProcessingAgentDetails}
          />
          <PrivateRouteAdmin
            path="/processingagentlist"
            component={ProccessingCenters}
          />
          <PrivateRouteAdmin
            path="/totalprocessingorders"
            component={TotalProcessingOrders}
          />
          <PrivateRouteAdmin path="/totalorder" component={TotalOrderTable} />

          <PrivateRouteAdmin
            path="/opendeliveryrequests"
            component={OpenDeliveryRequests}
          />

          <PrivateRouteAdmin
            path="/billing/orderList/:id"
            component={OrdersList}
          />

          <PrivateRouteAdmin path="/referralList" component={CommissionList} />

          <PrivateRouteAdmin
            path="/billing/orderDetails/:id"
            component={TransferAmount}
          />

          <PrivateRouteAdmin path="/OrderDetails" component={OrderDetails} />
          <PrivateRouteAdmin path="/TableDemo" component={TableDemo} />
          <PrivateRouteAdmin path="/storeprofile" component={StoreProfile} />
          <PrivateRouteAdmin path="/createadmin" component={CreateAdmin} />
          <PrivateRouteAdmin
            path="/editadmin/:type/:userId"
            component={CreateAdmin}
          />
          <PrivateRouteAdmin
            path="/viewadmin/:type/:userId"
            component={CreateAdmin}
          />

          <PrivateRouteAdmin path="/list" component={AdminList} />
          <PrivateRouteAdmin
            path="/termsconditions"
            component={TermsConditions}
          />
          <PublicRoute path="/tnc" component={Tnc} />
          <PublicRoute path="/account-info" component={AccountDelete} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/resetpassword" component={ResetPassword} />
          <PublicRoute path="/forgotpassword" component={AdminForgotPassword} />
        </Switch>
      </>
    )
  }
}
export default DelivaRoute
