/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react'
import { Row, Col, Table, Button, Form } from 'react-bootstrap'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import Loader from '../../common/Loader'
import {
  getLocalStorage,
  showErrorToast,
  showSuccessToast,
} from '../../../common/helpers/Utils'
import delivaApiService from '../../../common/services/apiService'
import moment from 'moment'
import CONSTANTS from '../../../common/helpers/Constants'
import { Modal, Image } from 'react-bootstrap'
import transfer from '../../../assets/images/money-transfer.png'

class TransferAmount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sortBy: '',
      sortOrder: '',
      search: '',
      orderList: [],
      roleId: '',
      totalCount: 0,
      showLoader: false,
      orderId: this.props.match.params.id,
      users: [],
      payModal: false,
      payData: {},
      allPayData: [],
      totalPay: false,
      onHoldPayment: false,
      payDisable: false,
      roleId: CONSTANTS.ROLES.ADMIN,
    }
  }

  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getOrderDetail(this.state.orderId)
    } else {
      this.props.history.push('/login')
    }
  }

  getOrderDetail(orderId) {
    this.setLoader(true)
    delivaApiService('getOrderDetailofOrderList', {
      orderId: this.props.match.params?.id,
      userId: getLocalStorage('id'),
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState(
            {
              orderDetail: response.data.resourceData.orderResponse,
              users: response.data.resourceData.users,
              onHoldPayment:
                response.data.resourceData.orderResponse.paymentHold,
            },
            () => {
              this.setLoader(false)
            }
          )
        } else {
          this.setLoader(false)
        }
      })
      .catch((error) => {
        this.setLoader(false)
      })
  }
  pay(user) {
    this.setState({ totalPay: false })
    this.setState({ payModal: true })
    this.setState({ payData: user })
  }
  allPay(users) {
    this.setState({ totalPay: true })
    const data = users
      .filter((user) => !user.transfered)
      .map((user) => ({
        userId: user?.userId,
        amount: user?.agentFees,
        deliveryRequestId: this.state.orderId,
        currency: user?.currency,
      }))
    this.setState({ allPayData: data })
    this.setState({ payModal: true })
  }
  mpay = () => {
    this.setState({ payDisable: true })
    let user = this.state.payData
    const data = {
      userId: user?.userId,
      amount: user?.agentFees == 0 ? 0 : user.agentFees,
      deliveryRequestId: this.state?.orderId,
      currency: user?.currency,
    }
    delivaApiService('transferFunds', data)
      .then((response) => {
        if (response && response.data) {
          if (response.data.status == 200) {
            showSuccessToast(response.data.responseMessage)
            this.getOrderDetail(this.state.orderId)
            this.setState({ payModal: false })
            this.setState({ payDisable: false })
          } else {
            showErrorToast(response.data.message)
            this.setState({ payModal: false })
            this.setState({ payDisable: false })
          }
        } else {
          showErrorToast(response.data.responseMessage)
          this.setLoader(false)
          this.setState({ payModal: false })
          this.setState({ payDisable: false })
        }
      })
      .catch((error) => {
        showErrorToast(error.message)
        this.setLoader(false)
        this.setState({ payModal: false })
        this.setState({ payDisable: false })
      })
  }
  mPayTotal = () => {
    this.setState({ payDisable: true })
    const data = { transferList: this.state.allPayData }
    delivaApiService('transferAllFunds', data)
      .then((response) => {
        if (response && response.data) {
          if (response.data.status == 200) {
            showSuccessToast(response.data.responseMessage)
            this.getOrderDetail(this.state.orderId)
            this.setState({ payModal: false })
            this.setState({ payDisable: false })
          } else {
            showErrorToast(response.data.message)
            this.setState({ payModal: false })
            this.setState({ payDisable: false })
          }
        } else {
          showErrorToast(response.data.responseMessage)
          this.setLoader(false)
          this.setState({ payModal: false })
          this.setState({ payDisable: false })
        }
      })
      .catch((error) => {
        this.setLoader(false)
        this.setState({ payModal: false })
        this.setState({ payDisable: false })
      })
  }
  holdPayment = (hold) => {
    this.setState({ onHoldPayment: !this.state.onHoldPayment }, () => {
      let data = {
        roleId: this.state.roleId,
        orderId: parseInt(this.state.orderId),
        hold: this.state.onHoldPayment,
      }
      delivaApiService('holdPayment', data)
        .then((response) => {
          if (response && response.data) {
            if (response.data.status == 200) {
              showSuccessToast(response.data.responseMessage)
            } else {
              showErrorToast(response.data.message)
            }
          } else {
            showErrorToast(response.data.responseMessage)
            this.setLoader(false)
          }
        })
        .catch((error) => {
          this.setLoader(false)
        })
    })
  }
  render() {
    const { orderDetail, orderId, users } = this.state
    console.log(users, 'lllll')

    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.payModal}
          onHide={() => this.setState({ payModal: false })}
          centered
        >
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={transfer} className="img-fluid user-block-img" />
              <h4 className="orange-text mt-3">Are You Sure?</h4>
              <p>Are you sure you want to transfer the amount ?</p>
              <button
                onClick={this.state.totalPay ? this.mPayTotal : this.mpay}
                className="b1-btn btn"
                disabled={this.state.payDisable}
              >
                Proceed
              </button>
              <button
                className="cancel-btn"
                onClick={() =>
                  this.setState({ payModal: false, totalPay: false })
                }
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable transfer-details">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3 align-items-end">
                    <Col md={6} lg={6} className="">
                      <h3 className="font-weight-normal mb-2 color-33">
                        Order Detail
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-head">
                        <span
                          onClick={() =>
                            this.props.history.push(
                              `/billing/orderList/${CONSTANTS.ROLES.ADMIN}`,
                              {
                                selectedValue:
                                  this.props.location.state.listFor,
                              }
                            )
                          }
                          className="pointer"
                        >
                          Order List
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        <span>Order Detail </span>
                      </h5>
                    </Col>
                    <Col md={6} lg={6} className="d-flex justify-content-end">
                      <Form>
                        <Form.Check // prettier-ignore
                          checked={this.state.onHoldPayment}
                          type="switch"
                          id="custom-switch"
                          label="Hold Payment"
                          disabled={this.state.users.every(
                            (user) => user.transfered === true
                          )}
                          onChange={() =>
                            this.holdPayment(this.state.onHoldPayment)
                          }
                        />
                      </Form>
                    </Col>
                  </Row>
                  <div className="request-user-box">
                    <Row>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Request ID</h3>
                          <p>{orderDetail?.requestId}</p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Request Date</h3>
                          <p>
                            {orderDetail?.requestDate
                              ? moment(orderDetail?.requestDate)
                                  .utcOffset('-00:00')
                                  .format('D MMM YY [at] h:mm A')
                              : '-'}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Destination Location</h3>
                          <p
                            title={orderDetail?.destinationLocation}
                            className="line-clamp2"
                          >
                            {orderDetail?.destinationLocation}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Source Location</h3>
                          <p
                            title={orderDetail?.sourceLocation}
                            className="line-clamp2"
                          >
                            {orderDetail?.sourceLocation}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="border-light my-3"></div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Expected Delivery Date</h3>
                          <p>
                            {orderDetail?.expectedDeliveryDate
                              ? moment(orderDetail?.expectedDeliveryDate)
                                  .utcOffset('-00:00')
                                  .format('D MMM YY [at] h:mm A')
                              : '-'}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Final Delivery Date</h3>
                          <p>
                            {orderDetail?.finalDeliveryDate
                              ? moment(orderDetail?.finalDeliveryDate)
                                  .utcOffset('-00:00')
                                  .format('D MMM YY [at] h:mm A')
                              : '-'}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Deliva Fees</h3>
                          <p>
                            {orderDetail?.currency === CONSTANTS.CURRENCY.USD
                              ? '$'
                              : orderDetail?.currency === CONSTANTS.CURRENCY.NGN
                              ? '₦'
                              : null}
                            {orderDetail?.delivaFees}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Transaction Fees</h3>
                          <p>
                            {orderDetail?.currency === CONSTANTS.CURRENCY.USD
                              ? '$'
                              : orderDetail?.currency === CONSTANTS.CURRENCY.NGN
                              ? '₦'
                              : null}
                            {orderDetail?.transactionFees}
                          </p>
                        </div>
                      </Col>
                      <Col md={3} className="d-none">
                        <div className="col-listing">
                          <h3 className="dark-gray">Total Amount</h3>
                          <p>{orderDetail?.totalAmount}</p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="border-light my-3"></div>
                      </Col>
                      <Col md={3}>
                        <div className="col-listing">
                          <h3 className="dark-gray">Docket Number</h3>
                          <p>
                            {orderDetail?.docketNumber
                              ? `#${orderDetail?.docketNumber}`
                              : '-'}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="transfer-box">
                    <div className="request-user-box-new">
                      <Row>
                        <Col md={12}>
                          <div className="transfer-table">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Agent Business Name</th>
                                  <th>Agent Fees</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {users?.map((user, ind) => (
                                  <tr key={ind}>
                                    <td> {user?.name}</td>
                                    <td>
                                      {user?.currency === CONSTANTS.CURRENCY.USD
                                        ? '$'
                                        : user?.currency ===
                                          CONSTANTS.CURRENCY.NGN
                                        ? '₦'
                                        : null}{' '}
                                      {user?.agentFees}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {user?.transfered ? (
                                        moment(user?.transferDate)
                                          .utcOffset('-00:00')
                                          .format('D MMM YY [at] h:mm A')
                                      ) : (
                                        <Button
                                          disabled={
                                            user?.transfered ||
                                            this.state.onHoldPayment ||
                                            user?.active === false ||
                                            user?.requestDelivered === false
                                          }
                                          onClick={() => this.pay(user)}
                                          className="active-btn transfer-btn mr-0"
                                        >
                                          Transfer
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <p className="black-color mb-0">
                                      Total:{' '}
                                      {orderDetail?.currency ===
                                      CONSTANTS.CURRENCY.USD
                                        ? '$'
                                        : orderDetail?.currency ===
                                          CONSTANTS.CURRENCY.NGN
                                        ? '₦'
                                        : null}
                                      {orderDetail?.totalAmount}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mb-4">
                              <div
                                className={`flex-grow-1 text-center ${
                                  users[0]?.transfered
                                    ? 'text-success'
                                    : 'text-danger'
                                }`}
                              >
                                {!users[0]?.active &&
                                users?.length > 0 &&
                                users[0]?.transfered === false &&
                                users[0]?.requestDelivered ? (
                                  <span>
                                    To activate transfers, please ensure your
                                    account is approved.
                                  </span>
                                ) : (
                                  ''
                                )}
                                {users[0]?.transfered ? (
                                  <span
                                    className={
                                      users[0]?.paymentStatusMessage?.includes(
                                        'success'
                                      )
                                        ? ''
                                        : 'text-danger'
                                    }
                                  >
                                    {users[0]?.paymentStatusMessage}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>

                              <Button
                                onClick={() =>
                                  this.props.history.push(
                                    `/billing/orderList/${CONSTANTS.ROLES.ADMIN}`,
                                    {
                                      selectedValue:
                                        this.props.location.state.listFor,
                                    }
                                  )
                                }
                                className="active-btn cancel-btns me-3"
                              >
                                Cancel
                              </Button>
                              {/* <Button
                                disabled={
                                  this.state.users.every(
                                    (user) => user.transfered === true
                                  ) || this.state.onHoldPayment
                                }
                                onClick={() => this.allPay(this.state.users)}
                                className="b1-btn"
                              >
                                Transfer To All
                              </Button> */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    )
  }
}
export default TransferAmount
