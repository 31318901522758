import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import matchSorter from "match-sorter";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col } from "react-bootstrap";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage } from "../../../common/helpers/Utils";
import Pagination from "../DeliveryAgent/perfectpage";
import moment from "moment";

class BidTables extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      searchInput: "",
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: "default", value: 5 }],
      pageSize: 5,
      apiname: "",
    };
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {

    this.setState(
      {
        pageName: this.props.match.params.name,
        apiName:
          this.props.match.params.name == "totalbids"
            ? "getSubmittedBidsForDA"
            : "getOpenBidsForDA",
      },
      () => {
        this.getOrders(this.state.apiName);
      }
    );
  }
  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }
  onRezise(page) {
  }
  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.details.filter((value) => {      
      return (
        value?.title?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.destinationPaDetail &&  value?.destinationPaDetail?.businessName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.destinationPaDetail &&  value?.destinationPaDetail?.city?.toLowerCase().includes(searchInput?.toLowerCase()) ||        
        value?.sourcePaDetail &&  value?.sourcePaDetail?.businessName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.sourcePaDetail &&  value?.sourcePaDetail?.city?.toLowerCase().includes(searchInput?.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  getOrders(apiName) {
    delivaApiService(apiName, { id: getLocalStorage("DAid") })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState(
            {
              details: response.data.resourceData.data,
              totalCount: response.data.resourceData.count,
            },
            () => {
              this.setState(
                {
                  data: this.state.details.map((item) => {
                    return item;
                  }),
                }
              );
            }
          );
        } else {
          //   this.setLoader(false);
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      });

    // }
  }
  hello(pageSize) {
    this.setState({
      pageSize,
    });
  }
  changepath = (path, props) => {
    this.props.history.push(path);
  };
  render() {
    const columns = [
      {
        Header: "Date",
        id: "bidDate",
        accessor: (d) => moment(new Date(d?.bidDate)).format("DD MMM YYYY"),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "bidDate", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["requestId"] }),
        filterAll: true,
      },

      {
        Header: "Package name",
        id: "title",
        accessor: (d) => d.title,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },

      {
        Header: "Destination location",
        id: "destinationPaDetail",
        accessor: (d) =>
          d?.destinationPaDetail ? ((d?.destinationPaDetail?.businessName || '') +
            ", " +
            (d?.destinationPaDetail?.city || '')):d.destinationCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationPaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["destinationPaDetail.city"],
          }),
        filterAll: true,
      },
      {
        Header: "Pickup location",
        id: "sourcePaDetail",
        accessor: (d) =>
        d?.sourcePaDetail ? ((d?.sourcePaDetail?.businessName || '') +
          ", " +
          (d?.sourcePaDetail?.city || '')):d.sourceCity,
        // accessor: (d) =>
        //   d?.sourcePaDetail?.businessName + ", " + d?.sourcePaDetail?.city,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "sourcePaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sourcePaDetail.city"] }),
        filterAll: true,
      },
      {
        Header: "Bid amount",
        id: "bidAmount",
        accessor: (d) => d.bidAmount,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "bidAmount", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["bidAmount"] }),
        filterAll: true,
      },
      {
        Header: "Status",
        id: "status",
        accessor: (d) => d.status,
        Cell: (props) => (
          <span className={`${props.value == "ACCEPTED_BID" ? "text-green" : "text-reddish"}`}>
            {props.value == "ACCEPTED_BID" ? "Accepted" : "Pending"}
          </span>
        ), // Custom cell components!
        // accessor: "status", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["status"] }),
        filterAll: true,
      },

      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {

          const result = matchSorter(rows, filter.value, {
            keys: [
              "destinationLocation",
              "orderDate",
              "pickUpLocation",
              "status",
              "packageName",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];

    const columns2 = [
      {
        Header: "Date",
        id: "bidDate",
        accessor: (d) =>  moment(new Date(d.bidDate)).format('DD MMM YYYY'),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["bidDate"] }),
        filterAll: true,
      },     

      {
        Header: "Package name",
        id: "title",
        accessor: (d) => d.title,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },

      {
        Header: "Destination location",
        id: "destinationPaDetail",
        accessor: (d) => 
        d?.destinationPaDetail ? ((d?.destinationPaDetail?.businessName || '') +
          ", " +
          (d?.destinationPaDetail?.city || '')):d.destinationCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationPaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["destinationCity"],
          }),
        filterAll: true,
      },
      {
        Header: "Pickup location",
        id: "sourcePaDetail",
        accessor: (d) => 
        d?.sourcePaDetail ? ((d?.sourcePaDetail?.businessName || '') +
          ", " +
          (d?.sourcePaDetail?.city || '')):d.sourceCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "sourcePaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sourceCity"] }),
        filterAll: true,
      },
      {
        Header: "Package weight",
        id: "weight",
        accessor: (d) => d.weight,
        Cell: (props) => <span>{props.value ? props.value : "-"}</span>, // Custom cell components!
        // accessor: "weight", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["weight"] }),
        filterAll: true,
      },

      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {

          const result = matchSorter(rows, filter.value, {
            keys: [
              "destinationLocation",
              "orderDate",
              "pickUpLocation",
              "status",
              "packageName",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33">
                        {this.props.match.params.name == "totalbids"
                          ? "Total Submitted Bids"
                          : "Open Bids"}
                        {" "}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-head">
                      <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentlist'
                            )
                          }
                          className="pointer"
                        >
                        Delivery agents
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentprofile'
                            )
                          }
                          className="pointer"
                        >
                        Delivery agent’s profile{" "}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        {this.props.match.params.name == "totalbids"
                          ? "Total Submitted Bids"
                          : "Open Bids"}
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by Order Id, Order date"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap total-bid-table">
                        <ReactTable className="totalSumbittedBidsTable"
                          data={this.state.data}
                          columns={
                            this.state.pageSize
                              ? this.state.pageName == "totalbids"
                                ? columns
                                : columns2
                              : []
                          }
                          //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={this.state.pageSize}
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          //   resized={this.state.resized}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                          }}
                          //   filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                          PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                          Enter Per Page{" "}
                          <input
                            type="text"
                            placeholder="07"
                            name="defaultPageSize"
                            value={this.state.pageSize}
                            onChange={(e) => this.hello(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default BidTables;
