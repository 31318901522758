import React, { Component } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import RejectIcon from '../../../assets/images/RejectIcon.svg'
import approved from '../../../assets/images/approved.svg'
import delivaApiService from '../../../common/services/apiService'
import validateInput from '../../../common/validations/validateProofs'
import { showErrorToast } from '../../../common/helpers/Utils'
import CONSTANTS from '../../../common/helpers/Constants'
import { error } from 'jquery'

class DeliveryAgentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile_detail: {},
      showRejectModal: false,
      passportVerification: false,
      nationalIdVerification: false,
      bankVerification: false,
      idproofVerification: false,
      lisenceIdProofVerification: false,
      lisenceVerificationDA: false,
      errors: {},
      rejectionReason: 'Other',
      successModal: false,
      idProof: CONSTANTS.DAIDPROOF.PASSPORTLICENCE,
      selectedOption: 'resubmit', // State for radio buttons
    }
  }

  handleRadioChange = (val) => {
    console.log(val, 'ppppppppp')
    this.setState({ selectedOption: val })
  }

  handleReject = (userId) => {
    this.setState({
      showRejectModal: !this.state.showRejectModal,
      userId,
    })
  }

  componentDidMount() {
    if (this.props && this.props.location.state.item) {
      this.getDeliveryAgentProfile(this.props.location.state.item.userId)
    }
  }

  getDeliveryAgentProfile = (id) => {
    this.setState({
      showLoader: true,
    })
    delivaApiService('deliveryAgentprofile', {
      daId: id,
    })
      .then((response) => {
        this.setState({ showLoader: false })
        if (response.data.status == 200) {
          this.setState({ profile_detail: response.data.resourceData })
          if (
            response.data.resourceData.identificationDetails
              .drivingLicenceNumber
          ) {
            this.setState({ idProof: CONSTANTS.DAIDPROOF.DRIVINGLICENCE })
          }
          if (
            response.data.resourceData.identificationDetails.nationalIdNumber
          ) {
            this.setState({ idProof: CONSTANTS.DAIDPROOF.NATIONALLICENCE })
          }
        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        })
      })
  }

  approveDA = (userId, name) => {
    if (this.isValid()) {
      this.setState({
        showLoader: true,
        daName: name,
        userId: userId,
        errors: {},
      })
      delivaApiService('approveDA', { userId })
        .then((response) => {
          this.setState({ showLoader: false, successModal: true })
          if (response.data.status == 200) {
            this.getDeliveryAgentProfile(userId)
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          })
        })
    }
  }

  approvalSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
    })
  }

  onChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if (!isValid) {
      this.setState({ errors })
    }
    return isValid
  }

  submitReject = () => {
    if (
      this.state.rejectionReason &&
      this.state.description &&
      this.state.userId
    ) {
      let data = {
        reasonForRejection: this.state.rejectionReason,
        rejectionDetail: this.state.description,
        userId: this.state.userId,
        docsResubmissionBlocked:
          this.state.selectedOption === 'blocked' ? true : false,
      }
      delivaApiService('rejectDA', data)
        .then((response) => {
          this.setState({ showLoader: false })
          if (response.data.status == 200) {
            this.setState({
              showRejectModal: !this.state.showRejectModal,
            })
            this.props.history.push('/verification/3')
            this.getDeliveryAgentProfile(data.userId)
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          })
        })
    } else {
      showErrorToast('Please Write Rejection Details')
    }
  }

  render() {
    const { profile_detail, errors } = this.state
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal fs22 mb-0 line-ht color-33">
                        Delivery agent
                      </h3>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      {/* <button className="yellow-text-btn color-blue fs20"> */}
                      <button
                        className={`${
                          profile_detail?.rejected ? 'color-red' : 'color-blue'
                        } background-none border-none fs20`}
                      >
                        {profile_detail?.rejected
                          ? 'Rejected'
                          : profile_detail.active == true
                          ? 'In-process'
                          : 'Pending'}
                      </button>
                    </Col>
                  </Row>
                  <div className="cust-details-wrap mb-4 delivery-agent-detail-bg ">
                    <Row noGutters>
                      <Col md={12} lg={12} className="">
                        <h5 className=" ">Personal details</h5>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={3} className="">
                        <div className="pr-wrap">
                          <h5 className="font-weight-normal color-grey fs18">
                            Name
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {profile_detail.name || ''}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Agent Id
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            #{profile_detail.userId}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Date of Registration
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {moment(
                              this.props?.location?.state?.item?.createdDate
                            )
                              .utc()
                              .format('DD MMM YYYY')}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Mobile No.
                          </h5>
                          {profile_detail.countryCode &&
                          profile_detail.mobile ? (
                            <h5 className="fs18 font-weight-normal">
                              +{profile_detail.countryCode}-
                              {profile_detail.mobile}
                            </h5>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col md={12} lg={12} className="px-5">
                        <hr className="yellow-hr" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={3}>
                        <h5 className="font-weight-normal color-grey fs18">
                          Email
                        </h5>
                        <h4 className="fs18 font-weight-normal">
                          {profile_detail.email}
                        </h4>
                      </Col>
                      <Col md={6} lg={6}>
                        <h5 className="font-weight-normal color-grey fs18">
                          Address
                        </h5>
                        <h5 className="fs18 font-weight-normal">
                          {profile_detail.address || ''}
                        </h5>
                      </Col>
                      <Col md={6} lg={3}></Col>
                    </Row>
                  </div>
                  <div className="card-layout-update">
                    <Row className="mb-3">
                      {/* Passport details */}
                      <Col md={6} lg={6} className="mb-5">
                        {profile_detail?.identificationDetails
                          ?.nationalIdNumber ? (
                          <div className="details-wrap mb-5">
                            <div>
                              <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                                National ID Details
                              </h4>
                              <hr className="mt-2" />
                              <div className="row">
                                <div className="col-sm-6">
                                  <h4 className="pl-4 font-weight-normal color-grey fs18">
                                    National ID Number
                                  </h4>
                                  <p className="fs20 pl-4 ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .nationalIdNumber
                                      ? profile_detail.identificationDetails
                                          .nationalIdNumber
                                      : ''}
                                  </p>
                                  {/* <h4 className="pl-4 font-weight-normal color-grey fs18 mt-4">
                                    Passport Issuing Country
                                  </h4> */}
                                  {/* <p className="fs20 pl-4 ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportIssuingCountry
                                      ? profile_detail.identificationDetails
                                          .passportIssuingCountry
                                      : ''}
                                  </p> */}
                                </div>
                                {/* <div className="col-sm-6">
                                  <h4 className="font-weight-normal color-grey fs18">
                                    Passport Expiration Date
                                  </h4>
                                  <p className="fs20  ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportExpirationDate
                                      ? moment(
                                          profile_detail.identificationDetails
                                            .passportExpirationDate
                                        ).format('DD MMM YYYY')
                                      : ''}
                                  </p>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <hr />
                              {profile_detail.active ? (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test5"
                                    name="nationalIdVerification"
                                    value={this.state.nationalIdVerification}
                                    checked
                                  />
                                  <label htmlFor="test5">Verified</label>
                                </div>
                              ) : (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test5"
                                    name="nationalIdVerification"
                                    value={this.state.nationalIdVerification}
                                    onChange={this.onChange.bind(this)}
                                  />
                                  <label htmlFor="test5">
                                    {this.state.nationalIdVerification
                                      ? 'Verified'
                                      : 'Verify'}
                                  </label>
                                  <div className="error text-danger">
                                    {errors.nationalIdVerification}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        {profile_detail?.identificationDetails
                          ?.passportNumber ? (
                          <div className="details-wrap mb-5">
                            <div>
                              <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                                Passport Details
                              </h4>
                              <hr className="mt-2" />
                              <div className="row">
                                <div className="col-sm-6">
                                  <h4 className="pl-4 font-weight-normal color-grey fs18">
                                    Passport Number
                                  </h4>
                                  <p className="fs20 pl-4 ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportNumber
                                      ? profile_detail.identificationDetails
                                          .passportNumber
                                      : ''}
                                  </p>
                                  <h4 className="pl-4 font-weight-normal color-grey fs18 mt-4">
                                    Passport Issuing Country
                                  </h4>
                                  <p className="fs20 pl-4 ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportIssuingCountry
                                      ? profile_detail.identificationDetails
                                          .passportIssuingCountry
                                      : ''}
                                  </p>
                                </div>
                                <div className="col-sm-6">
                                  <h4 className="font-weight-normal color-grey fs18">
                                    Passport Expiration Date
                                  </h4>
                                  <p className="fs20  ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportExpirationDate
                                      ? moment(
                                          profile_detail.identificationDetails
                                            .passportExpirationDate
                                        ).format('DD MMM YYYY')
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <hr />
                              {profile_detail.active ? (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test1"
                                    name="passportVerification"
                                    value={this.state.passportVerification}
                                    checked
                                  />
                                  <label htmlFor="test1">Verified</label>
                                </div>
                              ) : (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test1"
                                    name="passportVerification"
                                    value={this.state.passportVerification}
                                    onChange={this.onChange.bind(this)}
                                  />
                                  <label htmlFor="test1">
                                    {this.state.passportVerification
                                      ? 'Verified'
                                      : 'Verify'}
                                  </label>
                                  <div className="error text-danger">
                                    {errors.passportVerification}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div
                          className={
                            profile_detail?.identificationDetails
                              ?.passportNumber
                              ? 'details-wrap mt-4'
                              : 'details-wrap mt-0 mb-5'
                          }
                        >
                          <Row noGutters className="">
                            <Col md={12} lg={12} className="">
                              <h4 className="pl-4 fs20 font-weight-normal color-head">
                                ID Proof Images
                              </h4>
                            </Col>
                          </Row>
                          <div className="details-image-wrap smallWrapImg">
                            {profile_detail.identificationDetails &&
                            profile_detail.identificationDetails
                              .passportFrontImageUrl &&
                            profile_detail.identificationDetails
                              .passportBackImageUrl ? (
                              <Row>
                                <Col md={6} lg={6} className="pr-2">
                                  <div className="imgOuterDiv img-div-box">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .passportFrontImageUrl
                                      }
                                      className="img-fluid wd-auto"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} lg={6} className="pl-2">
                                  <div className="imgOuterDiv img-div-box">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .passportBackImageUrl
                                      }
                                      className="img-fluid wd-auto"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : profile_detail.identificationDetails &&
                              profile_detail.identificationDetails
                                .nationalFrontImageUrl &&
                              profile_detail.identificationDetails
                                .nationalBackImageUrl ? (
                              <Row>
                                <Col md={6} lg={6} className="pr-2">
                                  <div className="imgOuterDiv img-div-box">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .nationalBackImageUrl
                                      }
                                      className="img-fluid wd-auto"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} lg={6} className="pl-2">
                                  <div className="imgOuterDiv img-div-box">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .nationalFrontImageUrl
                                      }
                                      className="img-fluid wd-auto"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : profile_detail.identificationDetails &&
                              profile_detail.identificationDetails
                                .licenceFrontImageUrl &&
                              profile_detail.identificationDetails
                                .licenceBackImageUrl ? (
                              <Row>
                                <Col md={6} lg={6} className="pr-2">
                                  <div className="imgOuterDiv img-div-box">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .licenceFrontImageUrl
                                      }
                                      className="img-fluid wd-auto"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} lg={6} className="pl-2">
                                  <div className="imgOuterDiv img-div-box">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .licenceBackImageUrl
                                      }
                                      className="img-fluid wd-auto"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className="col-sm-12">
                            <hr />
                            <div className="deliva-radio text-center">
                              <input
                                type="checkbox"
                                id="test3"
                                name="idproofVerification"
                                value={this.state.idproofVerification}
                                checked={
                                  profile_detail.active
                                    ? true
                                    : this.state.idproofVerification
                                }
                                onChange={this.onChange.bind(this)}
                              />
                              <label htmlFor="test3">
                                {profile_detail.active
                                  ? 'Verified'
                                  : this.state.idproofVerification
                                  ? 'Verified'
                                  : 'Verify'}
                              </label>
                              <div className="error text-danger">
                                {errors.idproofVerification}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6} lg={6} className="mb-5 d-none">
                        {profile_detail?.identificationDetails
                          ?.nationalIdNumber ? (
                          <div className="details-wrap mb-5">
                            <div>
                              <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                                National ID Details
                              </h4>
                              <hr className="mt-2" />
                              <div className="row">
                                <div className="col-sm-6">
                                  <h4 className="pl-4 font-weight-normal color-grey fs18">
                                    National ID Number
                                  </h4>
                                  <p className="fs20 pl-4 ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .nationalIdNumber
                                      ? profile_detail.identificationDetails
                                          .nationalIdNumber
                                      : ''}
                                  </p>
                                  {/* <h4 className="pl-4 font-weight-normal color-grey fs18 mt-4">
                                    Passport Issuing Country
                                  </h4> */}
                                  {/* <p className="fs20 pl-4 ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportIssuingCountry
                                      ? profile_detail.identificationDetails
                                          .passportIssuingCountry
                                      : ''}
                                  </p> */}
                                </div>
                                {/* <div className="col-sm-6">
                                  <h4 className="font-weight-normal color-grey fs18">
                                    Passport Expiration Date
                                  </h4>
                                  <p className="fs20  ">
                                    {profile_detail.identificationDetails &&
                                    profile_detail.identificationDetails
                                      .passportExpirationDate
                                      ? moment(
                                          profile_detail.identificationDetails
                                            .passportExpirationDate
                                        ).format('DD MMM YYYY')
                                      : ''}
                                  </p>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <hr />
                              {profile_detail.active ? (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test5"
                                    name="nationalIdVerification"
                                    value={this.state.nationalIdVerification}
                                    checked
                                  />
                                  <label htmlFor="test5">Verified</label>
                                </div>
                              ) : (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test5"
                                    name="nationalIdVerification"
                                    value={this.state.nationalIdVerification}
                                    onChange={this.onChange.bind(this)}
                                  />
                                  <label htmlFor="test5">
                                    {this.state.nationalIdVerification
                                      ? 'Verified'
                                      : 'Verify'}
                                  </label>
                                  <div className="error text-danger">
                                    {errors.nationalIdVerification}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        {/* <div
                          className={
                            profile_detail?.identificationDetails
                              ?.passportNumber
                              ? 'details-wrap mt-4'
                              : 'details-wrap mt-0'
                          }
                        >
                          <Row noGutters className="">
                            <Col md={12} lg={12} className="">
                              <h4 className="pl-4 fs20 font-weight-normal color-head">
                                ID Proof images
                              </h4>
                            </Col>
                          </Row>
                          <div className="details-image-wrap smallWrapImg">
                            {profile_detail.identificationDetails &&
                            profile_detail.identificationDetails
                              .passportFrontImageUrl &&
                            profile_detail.identificationDetails
                              .passportBackImageUrl ? (
                              <Row>
                                <Col md={6} lg={6} className="pr-2">
                                  <div className="imgOuterDiv">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .passportFrontImageUrl
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} lg={6} className="pl-2">
                                  <div className="imgOuterDiv">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .passportBackImageUrl
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : profile_detail.identificationDetails &&
                              profile_detail.identificationDetails
                                .nationalFrontImageUrl &&
                              profile_detail.identificationDetails
                                .nationalBackImageUrl ? (
                              <Row>
                                <Col md={6} lg={6} className="pr-2">
                                  <div className="imgOuterDiv">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .nationalBackImageUrl
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} lg={6} className="pl-2">
                                  <div className="imgOuterDiv">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .nationalFrontImageUrl
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : profile_detail.identificationDetails &&
                              profile_detail.identificationDetails
                                .licenceFrontImageUrl &&
                              profile_detail.identificationDetails
                                .licenceBackImageUrl ? (
                              <Row>
                                <Col md={6} lg={6} className="pr-2">
                                  <div className="imgOuterDiv">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .licenceFrontImageUrl
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} lg={6} className="pl-2">
                                  <div className="imgOuterDiv">
                                    <Image
                                      src={
                                        profile_detail.identificationDetails
                                          .licenceBackImageUrl
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className="col-sm-12">
                            <hr />
                            <div className="deliva-radio text-center">
                              <input
                                type="checkbox"
                                id="test3"
                                name="idproofVerification"
                                value={this.state.idproofVerification}
                                checked={
                                  profile_detail.active
                                    ? true
                                    : this.state.idproofVerification
                                }
                                onChange={this.onChange.bind(this)}
                              />
                              <label htmlFor="test3">
                                {profile_detail.active
                                  ? 'Verified'
                                  : this.state.idproofVerification
                                  ? 'Verified'
                                  : 'Verify'}
                              </label>
                              <div className="error text-danger">
                                {errors.idproofVerification}
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </Col>

                      {profile_detail.identificationDetails &&
                      profile_detail.identificationDetails
                        .drivingLicenceNumber ? (
                        <Col md={6} lg={6} className="mb-5">
                          <div className="details-wrap">
                            <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                              Licence Details
                            </h4>
                            <hr className="mt-2" />
                            <div className="row">
                              <div className="col-sm-6">
                                <h4 className="pl-4 font-weight-normal color-grey fs18">
                                  Driving Licence Number
                                </h4>
                                <p className="fs20 pl-4 ">
                                  {profile_detail.identificationDetails &&
                                  profile_detail.identificationDetails
                                    .drivingLicenceNumber
                                    ? profile_detail.identificationDetails
                                        .drivingLicenceNumber
                                    : ''}
                                </p>
                                <h4 className="pl-4 font-weight-normal color-grey fs18 mt-4">
                                  Licence Issuing Country
                                </h4>
                                <p className="fs20 pl-4 ">
                                  {profile_detail.identificationDetails &&
                                  profile_detail.identificationDetails
                                    .licenceIssuingCountry
                                    ? profile_detail.identificationDetails
                                        .licenceIssuingCountry
                                    : ''}
                                </p>
                              </div>
                              <div className="col-sm-6">
                                <h4 className="font-weight-normal color-grey fs18">
                                  Licence Expiration Date
                                </h4>
                                <p className="fs20  ">
                                  {profile_detail.identificationDetails &&
                                  profile_detail.identificationDetails
                                    .licenceExpirationDate
                                    ? moment(
                                        profile_detail.identificationDetails
                                          .licenceExpirationDate
                                      ).format('DD MMM YYYY')
                                    : ''}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <hr />
                              {profile_detail.active ? (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test4"
                                    name="lisenceVerificationDA"
                                    value={this.state.lisenceVerificationDA}
                                    checked
                                  />
                                  <label htmlFor="test4">Verified</label>
                                </div>
                              ) : (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test4"
                                    name="lisenceVerificationDA"
                                    value={this.state.lisenceVerificationDA}
                                    onChange={this.onChange.bind(this)}
                                  />
                                  <label htmlFor="test4">
                                    {this.state.lisenceVerificationDA
                                      ? 'Verified'
                                      : 'Verify'}
                                  </label>
                                  <div className="error text-danger">
                                    {errors.lisenceVerificationDA}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      ) : null}

                      {/* Banking details */}
                      {profile_detail.bankingDetails ? (
                        <Col md={6} lg={6} className="mb-5">
                          <div className="details-wrap">
                            <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                              Banking Details
                            </h4>
                            <hr className="mt-2" />
                            <div className="row">
                              <div className="col-sm-6">
                                <h4 className="pl-4 font-weight-normal color-grey fs18">
                                  Account Holder Name
                                </h4>
                                <p className="fs20 pl-4 ">
                                  {profile_detail.bankingDetails.fullName || ''}
                                </p>
                                <h4 className="pl-4 font-weight-normal color-grey fs18 mt-4">
                                  Bank
                                </h4>
                                <p className="fs20 pl-4 ">
                                  {profile_detail.bankingDetails.bankName || ''}{' '}
                                  {profile_detail.bankingDetails.bankLocation ||
                                    ''}
                                </p>
                              </div>
                              {/* <div className="col-sm-6">
                                <h4 className="font-weight-normal color-grey fs18">
                                  Account Number
                                </h4>
                                <p className="fs20 ">
                                  {profile_detail.bankingDetails
                                    .accountNumber || ''}
                                </p>
                                <h4 className="font-weight-normal color-grey fs18 mt-4">
                                  ABA Number
                                </h4>
                                <p className="fs20 ">
                                  {profile_detail.bankingDetails.abaNumber ||
                                    ''}
                                </p>
                              </div> */}
                              <div className="col-sm-12">
                                <hr />
                                {profile_detail.active ? (
                                  <div className="deliva-radio text-center">
                                    <input
                                      type="checkbox"
                                      id="test2"
                                      name="bankVerification"
                                      checked
                                    />
                                    <label htmlFor="test2">Verified</label>
                                  </div>
                                ) : (
                                  <div className="deliva-radio text-center">
                                    <input
                                      type="checkbox"
                                      id="test2"
                                      name="bankVerification"
                                      value={this.state.bankVerification}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label htmlFor="test2">
                                      {this.state.bankVerification
                                        ? 'Verified'
                                        : 'Verify'}
                                    </label>
                                    <div className="error text-danger">
                                      {errors.bankVerification}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </Row>

                    {!profile_detail.active ? (
                      <Row>
                        <Col md={12} lg={12}>
                          <div className="text-center mt-5">
                            {
                              <button
                                className="reject-btn"
                                onClick={this.handleReject.bind(
                                  this,
                                  profile_detail.userId
                                )}
                              >
                                Reject
                              </button>
                            }
                            <button
                              className="approve-btn"
                              onClick={this.approveDA.bind(
                                this,
                                profile_detail.userId,
                                profile_detail.name
                              )}
                            >
                              Approve
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Modal
          show={this.state.showRejectModal}
          onHide={this.handleReject.bind(this, '')}
          centered
          className="deliva-popup"
        >
          <Modal.Body>
            <div className="modal-content-wrap ">
              <div className="text-center">
                <Image
                  src={RejectIcon}
                  className="img-fluid reject-img pointer"
                  onClick={this.handleReject.bind(this, '')}
                />
                <h4 className="orange-text mt-3 fs-22">
                  Are you sure to reject this request ?
                </h4>
                <p className="color-head">
                  Please add/choose the reason of rejection{' '}
                </p>
              </div>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="color-head">
                  Choose the Reason
                </Form.Label>
                <Form.Control
                  as="select"
                  name="rejectionReason"
                  onChange={this.onChange}
                  value={this.state.rejectionReason}
                  className="py-0"
                >
                  <option value="Other">Other</option>
                  <option value="Invalid Passport Details">
                    Invalid Passport Details
                  </option>
                  <option value="Invalid Banking Details">
                    Invalid Banking Details
                  </option>
                  <option value="Invalid ID Proof">Invalid ID Proof</option>
                  <option value="Criminal Background">
                    Criminal Background
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="color-head">
                  Rejection details
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="description"
                  maxlength="100"
                  onChange={this.onChange}
                />
                <span className="min-char">Min 100 chars</span>
              </Form.Group>
              <div className="text-left mb-4">
                <div className="mb-2">Action</div>
                <Form.Check
                  type="radio"
                  label="Resubmission"
                  name="radioOptions"
                  id="radio1"
                  title="Enables the user to submit the document again."
                  checked={this.state.selectedOption === 'resubmit'}
                  onChange={() => this.handleRadioChange('resubmit')}
                  className="d-inline-block me-2"
                />
                <Form.Check
                  type="radio"
                  label="Block"
                  name="radioOptions"
                  id="radio2"
                  title="Prevents the user from resubmitting the document."
                  checked={this.state.selectedOption === 'blocked'}
                  onChange={() => this.handleRadioChange('blocked')}
                  className="d-inline-block mx-2"
                />
              </div>
              <div className="text-center">
                <button
                  className="b1-btn"
                  onClick={this.submitReject.bind(this)}
                >
                  Yes
                </button>
                <button
                  className="cancel-btn"
                  onClick={this.handleReject.bind(this, '')}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.successModal}
          onHide={() => this.approvalSuccessModal(this)}
          centered
        >
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={approved} className="img-fluid user-block-img" />
              <h4 className="lightGreenText mt-3">Approved</h4>
              <p className="color-grey">
                {this.state.daName} #{this.state.userId} details are approved
              </p>

              <button
                className="cancel-btn ml-0 blueBg whiteText"
                onClick={() => {
                  // this.approvalSuccessModal(this),
                  this.props.history.push('/verification/3')
                }}
              >
                Back
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default DeliveryAgentDetails
