import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import userblock1 from '../../../assets/images/userblock1.svg'
// import Unblock from "../../../assets/images/Unblock.svg";
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'

class TotalOrderTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: '',
      keyword: '',
      search: '',
      totalOrders: [],
    }
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      )
    } else {
      this.props.history.push('/login')
    }
  }
  update() {
    this.getList(1, this.state.records, 'desc', 'eventName', '')
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(
        1,
        e.target.value,
        this.state.sort,
        this.state.keyword,
        this.state.search
      )
    } else {
      this.setState({
        records: '',
      })
    }
  }
  onChangeSearch(e) {
    if (e.target.value.length > 3) {
      this.getList(
        1,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        e.target.value
      )
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      })
      if (e.target.value.length === 0) {
        this.getList(
          1,
          this.state.records,
          this.state.sort,
          this.state.keyword,
          e.target.value
        )
      }
    }
  }
  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.getList(
        1,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      )
    }
  }
  onChangeSort(keyword, sort) {
    if (this.state.keyword === keyword) {
      this.getList(
        this.state.pageno,
        this.state.records,
        sort,
        keyword,
        this.state.search
      )
    } else {
      this.getList(
        // this.state.pageno,
        1, //pagenodoubt
        this.state.records,
        'desc',
        keyword,
        this.state.search
      )
    }
  }
  getList(pageno, records, sort, keyword, search) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sort: sort,
        keyword: keyword,
        search: search,
      },
      () => {
        this.setLoader(true)
      }
    )
    delivaApiService('getAllOrders', {
      id: getLocalStorage('id'),
      records: records,
      pageNumber: pageno,
      // sort: sort,
      // keyword: keyword,
      // search: search,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.resourceData.totalOrdersCount)
          this.setState(
            {
              totalOrders: response.data.resourceData.totalOrders,
              totalOrdersCount: response.data.resourceData.totalOrdersCount,
            },
            () => {
              this.setLoader(false)
            }
          )
        } else {
          this.setLoader(false)
        }
      })
      .catch(
        this.setState({
          // showLoader: false,
        })
      )
  }
  getPager(total) {
    let startPage = this.state.startPage
    let endPage = this.state.endPage
    let totalPage = Math.ceil(total / this.state.records)
    let pageno = this.state.pageno

    if (totalPage <= 5) {
      startPage = 1
      endPage = totalPage
    } else {
      if (pageno <= 3) {
        startPage = 1
        endPage = 5
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4
        endPage = totalPage
      } else {
        startPage = pageno - 2
        endPage = pageno + 2
      }
    }
    let startIndex = (pageno - 1) * this.state.records
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1)

    // create an array of pages to ng-repeat in the pager control
    let pageArray
    if (startPage === endPage) {
      pageArray = [1]
    } else {
      pageArray = range(startPage, endPage)
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    })
  }

  onChangePage(page) {
    if (page === this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      )
    }
  }
  blockUnblock() {
    this.setLoader(true)
    let data = {
      // block: this.state.blockFlag,
      userId: this.state.blockId,
    }
    delivaApiService('blockUnblockCustomer', data).then((response) => {
      this.handleInviteVendors('')
      this.setLoader(false)
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      )
    })
  }
  setBlockData(id, flag) {
    this.setState(
      {
        blockId: id,
        blockFlag: flag,
      },
      () => {
        this.props.handleBlockStatus(flag)
      }
    )
  }

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    })
  }
  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    })
  }
  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors('', '')}
          centered
        >
          {/* <Modal.Header closeButton className="border-0">
                
                </Modal.Header> */}
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="orange-text mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{' '}
                {this.state.blockedStatus === true ? 'unblock' : 'block'} this
                user?{' '}
                {this.state.blockedStatus === false
                  ? 'You can not view this profile once blocked.'
                  : ''}
              </p>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus === true ? 'Unblock' : 'Block'}
              </button>
              <button
                className="cancel-btn"
                onClick={() => this.handleInviteVendors('')}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33">
                        Total Orders{' '}
                        <span className="color-grey">
                          ({this.state.totalOrdersCount})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-grey">
                        Total Orders{' '}
                        <span className="icon-next ml-2 Fs12"></span>{' '}
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <FormControl
                        type="text"
                        placeholder="Search by CustomerID, Location, Status, Name"
                        className="searchTxt"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-sm-12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Order Id{' '}
                              <a className="icon-up-and-down color-light-grey"></a>
                            </th>
                            <th>
                              Order Name{' '}
                              <a className="icon-up-and-down color-light-grey"></a>
                            </th>
                            <th>
                              Pickup Location{' '}
                              <a className="icon-up-and-down color-light-grey"></a>
                            </th>
                            <th>
                              Destination Location{' '}
                              <a className="icon-up-and-down color-light-grey"></a>
                            </th>
                            <th>
                              Status{' '}
                              <a className="icon-up-and-down color-light-grey"></a>
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {/* customerName: "Test 1234567890"
deliveryAgentName: "ved"
destinationLocation: "indore"
orderDate: 1594024038114
orderId: 7
packageName: "Package Imp 3"
packageWeight: "0.0gm"
pickUpLocation: "bangalore"
receiverName: "Shoppers 2" */}

                          {this.state.totalOrders &&
                            this.state.totalOrders.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.orderId}</td>
                                  <td>
                                    {item.packageName ? item.packageName : '-'}
                                  </td>
                                  <td>
                                    {item.pickUpLocation
                                      ? item.pickUpLocation
                                      : '-'}
                                  </td>

                                  <td>
                                    {item.destinationLocation
                                      ? item.destinationLocation
                                      : '-'}
                                  </td>
                                  <td className="pl-4 text-green">
                                    <div className="flex-active">
                                      <span className="ab-dot green"></span>
                                      {item.active === true
                                        ? 'Active'
                                        : 'Inactive'}
                                    </div>
                                  </td>
                                  <td>
                                    <a className="pointer">
                                      <span className="icon-eye"></span> View
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                      <nav aria-label="Page navigation">
                        <ul className="pagination pg-blue deliva-pagination justify-content-end pr-180p">
                          <li className="page-item">
                            <button
                              className="page-link rotate-180 control-btn"
                              aria-label="Previous"
                              onClick={() =>
                                this.onChangePage(this.state.pageno - 1)
                              }
                              disabled={
                                this.state.pageno === 1 ||
                                this.state.totalPage === 0
                              }
                            >
                              <span className="icon-next"></span>
                              <span
                                //className="prevNext"
                                className={`sr-only ${
                                  this.state.pageno === 1 ||
                                  this.state.totalPage === 0
                                    ? ''
                                    : 'active'
                                }`}
                              >
                                Previous
                              </span>
                            </button>
                          </li>
                          {this.state.totalPage > 0 &&
                            this.state.pageArray.map((page, ind) => {
                              return (
                                <li className="page-item">
                                  <a
                                    className={`page-link ${
                                      this.state.pageno === page ? 'active' : ''
                                    }`}
                                    onClick={() => this.onChangePage(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              )
                            })}

                          <li className="page-item">
                            <button
                              className="page-link control-btn"
                              aria-label="Next"
                              onClick={() =>
                                this.onChangePage(this.state.pageno + 1)
                              }
                              disabled={
                                this.state.pageno === this.state.totalPage ||
                                this.state.totalPage === 0
                              }
                            >
                              <span className="icon-next"></span>
                              <span
                                className={`sr-only ${
                                  this.state.pageno === this.state.totalPage ||
                                  this.state.totalPage === 0
                                    ? ''
                                    : 'active'
                                }`}
                              >
                                Next
                              </span>
                            </button>
                          </li>
                          <li className="page-value">
                            Enter Per Page{' '}
                            <input
                              type="text"
                              placeholder="07"
                              name="records"
                              value={this.state.records}
                              onChange={(e) => this.onChangeItemPerPage(e)}
                            />
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default TotalOrderTable
// export default withRouter(TotalOrderTable);
