import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { getLocalStorage } from '../common/helpers/Utils'

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        // getLocalStorage('vendorInfo') ? (
        //   <Redirect to="/vendor/dashboard" />
        // ) : getLocalStorage('organizerInfo') ? (
        //   <Redirect to="/organizer/dashboard" />
        // ) :
        props.location.pathname === '/tnc' ||
        props.location.pathname === '/account-info' ? (
          <Component {...props} />
        ) : getLocalStorage('adminInfo') ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default PublicRoute
