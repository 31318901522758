import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class Footer extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="content-wrapper border-0 pt-0 mb-0 pb-2">
          <Row noGutters className="bor-top">
            <Col md={12} lg={12} className="logo">
              <p className="footer-txt">
                Deliva ©{new Date().getFullYear()} All rights reserved.
                &nbsp;&nbsp;&nbsp; <a href='/tnc' target='_blank'>Terms and Conditions | Privacy Policy</a>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Footer;
