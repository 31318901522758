import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import Unblock from '../../../assets/images/Unblock.svg'
import Modal from 'react-bootstrap/Modal'
import userblock1 from '../../../assets/images/userblock1.svg'
// import "../../../App.scss"
import Loader from '../../common/Loader'
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'
import CONSTANTS from '../../../common/helpers/Constants'

class DeliveryAgentTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      delivery_agent_list: [],
      pageno: 1,
      records: 10,
      totalCount: '',
      sortBy: '',
      sortOrder: 'desc',
      keyword: '',
      search: '',
      showInviteVendors: false,
      blockedDueToCancellationLimit: '',
    }
  }

  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    })
  }

  componentDidMount() {
    this.getDeliveryAgent(
      this.state.pageno,
      this.state.records,
      this.state.sortBy,
      this.state.sortOrder
    )
  }

  getDeliveryAgent = (pageno, records, sortBy, sortOrder) => {
    this.setState({
      delivery_agent_list: [],
      showLoader: true,
      pageno: pageno,
      records: records,
      sortBy: sortBy,
      sortOrder: sortOrder,
    })
    delivaApiService('deliveryAgentList', {
      pageNumber: pageno,
      records: records == 0 ? CONSTANTS.SHOWRECORDS.NOOFRECORDS : records,
      blockedDueToCancellationLimit: this.state.blockedDueToCancellationLimit,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchByProfile: this.state.search,
    })
      .then((response) => {
        this.setState({ showLoader: false })
        if (response.data.status == 200) {
          this.setState({
            delivery_agent_list: response.data.resourceData.dAList,
            totalCount: response.data.resourceData.totalCount,
          })
          this.getPager(response.data.resourceData.totalCount)
        } else {
          this.setState({
            delivery_agent_list: [],
            totalCount: 0,
          })
          this.setLoader(false)
        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        })
      })
  }

  //manage page counts
  getPager(total) {
    this.setState(
      {
        records:
          this.state.records == 0
            ? CONSTANTS.SHOWRECORDS.NOOFRECORDS
            : this.state.records,
      },
      () => {
        let startPage = this.state.startPage
        let endPage = this.state.endPage
        let totalPage = Math.ceil(total / this.state.records)
        let pageno = this.state.pageno

        if (totalPage <= 5) {
          startPage = 1
          endPage = totalPage
        } else {
          if (pageno <= 3) {
            startPage = 1
            endPage = 5
          } else if (pageno + 1 >= totalPage) {
            startPage = totalPage - 4
            endPage = totalPage
          } else {
            startPage = pageno - 2
            endPage = pageno + 2
          }
        }
        let startIndex = (pageno - 1) * this.state.records
        let endIndex = Math.min(
          startIndex + this.state.records - 1,
          totalPage - 1
        )

        // create an array of pages to ng-repeat in the pager control
        let pageArray
        if (startPage == endPage) {
          pageArray = [1]
        } else {
          pageArray = range(startPage, endPage)
        }
        this.setState({
          // records: this.state.records,
          totalPage: totalPage,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pageArray: pageArray,
        })
      }
    )
  }

  // change page

  onChangePage(page) {
    if (page == this.state.pageno) {
    } else {
      this.getDeliveryAgent(page, this.state.records)
    }
  }

  //change data per page
  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getDeliveryAgent(1, e.target.value)
    } else {
      this.setState({
        records: '',
      })
    }
  }

  //block and unblock api call
  blockUnblock() {
    this.setState({ showLoader: true })
    let data = {
      userId: this.state.blockId,
    }
    delivaApiService('blockUnblockCustomer', data)
      .then((response) => {
        this.handleInviteVendors('')
        this.setState({ showLoader: false })
        this.getDeliveryAgent(this.state.pageno, this.state.records)
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        })
      })
  }

  deliveryAgentProfile = (item) => {
    if (!item.blocked) {
      this.props.history.push('/deliveryagentprofile', { item })
    }
  }

  handleChangeTab(val) {
    this.setState({ pageno: 1 })
    if (val == 'now') {
      this.setState({ blockedDueToCancellationLimit: '' }, () => {
        this.getDeliveryAgent(this.state.pageno, this.state.records)
      })
    } else {
      this.setState({ blockedDueToCancellationLimit: 'yes' }, () => {
        this.getDeliveryAgent(this.state.pageno, this.state.records)
      })
    }
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      if (this.state.search.length == 0) {
        this.getDeliveryAgent(this.state.pageno, this.state.records)
      }
    })
  }

  handleEnter = (e) => {
    if (e.key == 'Enter') {
      this.getDeliveryAgent(this.state.pageno, this.state.records)
    }
  }

  handleSearch() {
    if (this.state.search.trim() != '') {
      this.getDeliveryAgent(this.state.pageno, this.state.records)
    }
  }

  sortDeliveryAgent(val) {
    this.setState({ sortOrder: this.state.sortOrder == 'desc' ? '' : 'desc' })
    if (this.state.sortOrder == 'desc') {
      this.getDeliveryAgent(this.state.pageno, this.state.records, val, 'asc')
    } else {
      this.getDeliveryAgent(this.state.pageno, this.state.records, val, 'desc')
    }
  }

  render() {
    const { delivery_agent_list } = this.state
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors('', '')}
          centered
        >
          {/* <Modal.Header closeButton className="border-0">
                    
                    </Modal.Header> */}
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="orange-text mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{' '}
                {this.state.blockedStatus == true ? 'unblock' : 'block'} this
                user?{' '}
                {this.state.blockedStatus == false
                  ? 'You can not view this profile once blocked.'
                  : ''}
              </p>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus == true ? 'Unblock' : 'Block'}
              </button>
              <button
                className="cancel-btn"
                onClick={() => this.handleInviteVendors('')}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal fs22 mb-0 line-ht color-33">
                        Delivery agents{' '}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <FormControl
                        type="text"
                        placeholder="Search by Name, Agent Code, Phone Number"
                        className="searchTxt"
                        onChange={(e) => this.handleChangeInput(e)}
                        onKeyDown={this.handleEnter.bind(this)}
                      />
                      <span
                        className="icon-search"
                        onClick={() => this.handleSearch()}
                      ></span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} lg={12} className="">
                      <button
                        className={
                          this.state.blockedDueToCancellationLimit == ''
                            ? 'bor-da-btn mr-3'
                            : 'bor-btn grey-btn mr-3'
                        }
                        onClick={() => {
                          this.handleChangeTab('now')
                        }}
                      >
                        Delivering Now
                      </button>
                      <button
                        // className="bor-btn grey-btn"
                        className={
                          this.state.blockedDueToCancellationLimit == 'yes'
                            ? 'bor-da-btn'
                            : 'bor-btn grey-btn'
                        }
                        onClick={() => {
                          this.handleChangeTab('blocked')
                        }}
                      >
                        Blocked due to crossing cancellation limit
                      </button>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-sm-12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Agent Code{' '}
                              <a
                                href="#"
                                onClick={() =>
                                  this.sortDeliveryAgent('agentCode')
                                }
                                className="icon-up-and-down color-light-grey"
                              ></a>
                            </th>
                            <th>
                              Name{' '}
                              <a
                                href="#"
                                onClick={() => this.sortDeliveryAgent('name')}
                                className="icon-up-and-down color-light-grey"
                              ></a>
                            </th>

                            <th>
                              Phone Number{' '}
                              <a
                                href="#"
                                onClick={() =>
                                  this.sortDeliveryAgent('phoneNumber')
                                }
                                className="icon-up-and-down color-light-grey"
                              ></a>
                            </th>
                            <th style={{ width: '250px' }}>
                              Email{' '}
                              <a
                                href="#"
                                onClick={() => this.sortDeliveryAgent('email')}
                                className="icon-up-and-down color-light-grey"
                              ></a>
                            </th>
                            <th>
                              Status{' '}
                              <a
                                href="#"
                                onClick={() => this.sortDeliveryAgent('status')}
                                className="icon-up-and-down color-light-grey"
                              ></a>
                            </th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {!this.state.delivery_agent_list.length ? (
                            <tr>
                              <td colSpan={7} style={{ textAlign: 'center' }}>
                                No Records Available
                              </td>
                            </tr>
                          ) : (
                            this.state.delivery_agent_list.map((item, i) => {
                              return (
                                <tr key={Math.random()}>
                                  <td>#{item.userId}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    +{item.countryCode} {item.mobile}
                                  </td>
                                  <td style={{ width: '250px' }}>
                                    {item.email}
                                  </td>
                                  {!item.blocked ? (
                                    <td className="pl-4 text-green">
                                      <div className="flex-active">
                                        <span className="ab-dot green"></span>
                                        Active
                                      </div>
                                    </td>
                                  ) : (
                                    <td className="pl-4 text-red">
                                      <div className="flex-active">
                                        <span className="ab-dot red"></span>
                                        Blocked
                                      </div>
                                    </td>
                                  )}

                                  <td>
                                    {/* <a href className="pointer"><span className="icon-edit-ico"></span> Edit</a>&nbsp;&nbsp;&nbsp;&nbsp; */}
                                    {!item.blocked ? (
                                      <a
                                        className="pointer"
                                        onClick={() =>
                                          this.deliveryAgentProfile(item)
                                        }
                                      >
                                        <span className="icon-eye"></span> View
                                      </a>
                                    ) : null}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                      className="pointer"
                                      onClick={() =>
                                        this.handleInviteVendors(
                                          item.userId,
                                          item.blocked
                                        )
                                      }
                                    >
                                      <span
                                        className={
                                          item.blocked
                                            ? 'icon-password---Copy'
                                            : 'icon-unblockicon'
                                        }
                                      ></span>
                                      &nbsp;
                                      {item.blocked ? 'Unblock' : 'Block'}
                                    </a>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                      {this.state.delivery_agent_list.length ? (
                        <nav aria-label="Page navigation">
                          <ul className="pagination pg-blue deliva-pagination justify-content-end">
                            <li className="page-item">
                              <button
                                className="page-link rotate-180 control-btn"
                                aria-label="Previous"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno - 1)
                                }
                                disabled={
                                  this.state.pageno == 1 ||
                                  this.state.totalPage == 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  //className="prevNext"
                                  className={`sr-only ${
                                    this.state.pageno == 1 ||
                                    this.state.totalPage == 0
                                      ? ''
                                      : 'active'
                                  }`}
                                >
                                  Previous
                                </span>
                              </button>
                            </li>

                            {this.state.totalPage > 0 &&
                              this.state.pageArray.map((page, ind) => {
                                return (
                                  <li className="page-item" key={Math.random()}>
                                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                    <a
                                      className={`page-link ${
                                        this.state.pageno == page
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() => this.onChangePage(page)}
                                    >
                                      {page}
                                    </a>
                                  </li>
                                )
                              })}

                            <li className="page-item">
                              <button
                                className="page-link control-btn"
                                aria-label="Next"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno + 1)
                                }
                                disabled={
                                  this.state.pageno == this.state.totalPage ||
                                  this.state.totalPage == 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  className={`sr-only ${
                                    this.state.pageno == this.state.totalPage ||
                                    this.state.totalPage == 0
                                      ? ''
                                      : 'active'
                                  }`}
                                >
                                  Next
                                </span>
                              </button>
                            </li>

                            <li className="page-value">
                              Enter Per Page{' '}
                              <input
                                type="text"
                                placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                                name="records"
                                value={this.state.records}
                                onChange={(e) => this.onChangeItemPerPage(e)}
                              />
                            </li>
                          </ul>
                        </nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    )
  }
}
export default DeliveryAgentTable
