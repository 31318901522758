export default {
  firstName: {
    required: 'Please enter first name',
    alphaOnly: 'First name could only consist alphabets',
  },
  lastName: {
    required: 'Please enter last name',
    alphaOnly: 'Last name could only consist alphabets',
  },
  email: {
    required: 'Please enter email',
    invalid: 'Please enter valid email',
  },
  ticket_url: {
    required: 'Please enter ticket url',
    correct_url: 'Please enter a valid url',
  },
  password: {
    required: 'Please enter password',
    confirmPassword: 'Please enter confirm password',
    same: 'Mismatch password and confirm password',
    resetSame: 'Mismatch new password and confirm new password',
    newPassword: 'Please enter new password',
    oldPassword: 'Please enter current password',
    confirmNewPassword: 'Please enter confirm new password',
    repeatNewPassword: 'Please enter confirm password',
    spaceAvoid: 'Please enter a valid password',
    spacialChar:
      'Password must contain at least 8 characters, including at least one number, one special character and both lower and uppercase letters',
  },

  phoneNumber: {
    required: 'Please enter mobile number',
    invalid: 'Please enter a valid mobile number',
  },
  title: {
    required: 'Please enter title',
    invalid: 'Please enter a valid title',
  },
  address: {
    required: 'Please enter address',
  },
  organisationName: {
    required: 'Please enter organization name',
  },
  start_date: {
    required: 'Please enter start date',
  },
  start_time: {
    required: 'Please enter start time',
  },
  end_date: {
    required: 'Please enter end date',
  },
  end_time: {
    required: 'Please enter end time',
  },
  ingredients: {
    required: 'Please enter ingredients time',
  },
  allergy: {
    required: 'Please enter allergy time',
  },
  cook_time: {
    required: 'Please enter cook time',
  },
  topping: {
    required: 'Please enter topping',
  },
  batch_qty: {
    required: 'Please enter batch quantity',
    notZero: 'Please enter a valid quantity',
  },
  category_id: {
    required: 'Please enter a food category',
  },
  quantity: {
    required: 'Please enter quantity',
  },
  price: {
    required: 'Please enter price',
    notZero: 'Please enter a valid price',
  },
  ingredients: {
    required: 'Please select ingredients',
  },
  sizes: {
    required: 'Please add Price',
    notZero: 'Please enter a valid price',
  },
  diet_id: {
    required: 'Please select diet category',
  },
  cuisine_id: {
    required: 'Please select cuisine type',
  },
  big_image: {
    required: 'Please enter big image',
  },
  tile_image: {
    required: 'Please enter tile image',
  },
  food_category_title: {
    required: 'Please select food category',
  },
  item_category_title: {
    required: 'Please select item category',
  },
  start_time: {
    required: 'Please enter start time',
  },
  end_time: {
    required: 'Please enter end time',
  },
  per_batch_quantity: {
    required: 'Please enter per batch quantity',
  },
  cook_time: {
    required: 'Please enter cook time',
    notZero: 'Please enter a valid cook time',
  },
  //         food_category_id: "",
  //         start_time: null,
  //         end_time: null,
  //         per_batch_quantity: "",
  //         cook_time: "",
  cuisines: {
    required: 'Please select a cuisine',
  },
  trendingDishes: {
    required: 'Please select your trending dishes',
  },
  description: {
    required: 'Please enter description',
  },
  country: {
    required: 'Please enter country',
  },

  city: {
    required: 'Please enter city',
  },
  role: {
    required: 'Please enter role',
  },
};
