import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import Sidebar from '../commonAdmin/Sidebar';
import Header from '../commonAdmin/Header';
import Footer from '../commonAdmin/Footer';
import Loader from '../../common/Loader';
import delivaApiService from '../../../common/services/apiService';

import TotalOrder from '../../../assets/images/totalOrder.svg';
import CompletedOrder from '../../../assets/images/completedOrders.svg';
import CancelOrder from '../../../assets/images/cancelOrder.svg';
import IntransitOrder from '../../../assets/images/intransit1.svg';
import StatsIco1 from '../../../assets/images/statsIco1.svg';
import StatsIco2 from '../../../assets/images/statsIco2.svg';
import StatsIco3 from '../../../assets/images/statsIco3.svg';
import StatsIco4 from '../../../assets/images/statsIco4.svg';
import Template from "../../../common/helpers/Template";
import '../../../assets/scss/icomoon.scss';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      order_statistics_list: '',
      site_statistics_list: '',
      pending_approvals_list: '',
    };
  }
  componentDidMount() {
    this.getOrder();
    this.getPendingApprovals();
    this.getSiteStatistics();
  }

  getOrder() {
    this.setState({ showLoader: true });
    delivaApiService('orderStatistics', {})
      .then((response) => {
        this.setState({ showLoader: false });
        this.setState({ order_statistics_list: response.data.resourceData });
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        });
      });
  }

  getPendingApprovals = () => {
    delivaApiService('pendingApprovals')
      .then((response) => {
        this.setState({ showLoader: false });
        this.setState({
          pending_approvals_list: response.data.resourceData,
        });
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        });
      });
  };

  getSiteStatistics = () => {
    delivaApiService('siteStatistics')
      .then((response) => {
        this.setState({ showLoader: false });
        this.setState({
          site_statistics_list: response.data.resourceData,
        });
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        });
      });
  };

  changepath = (path) => {
    this.props.history.push(path);
  };

  render() {
    const {
      order_statistics_list,
      site_statistics_list,
      pending_approvals_list,
    } = this.state;
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer dashboard">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="font-weight-normal mb-4 color-33">
                        Order Statistics
                      </h3>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 ">
                          <div className="dash-b-wrapper pointer"
                          onClick={() => this.changepath(`/orders/total`)}>
                            <div className="row">
                              <div className="col-md-8 pl-0 ">
                                <h4 className="color-grey font-weight-normal font-22">
                                  {Template.TOTALORDERS}
                                </h4>
                                <h1 className="dark-grey t-value mb-0 opacity-dec">
                                  {order_statistics_list?.totalOrdersCount
                                    ? order_statistics_list?.totalOrdersCount
                                    : '0'}
                                </h1>
                              </div>
                              <div className="col-md-4 p-0">
                                <div className="text-right">
                                  <Image src={TotalOrder} className="img-fluid" />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                          <div className="bottom-box"></div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer"
                          onClick={() =>
                            this.changepath(`/orders/intransit`)
                          }>
                            <div className="row">
                              <div className="col-md-8 pl-0">
                                <h4 className="color-grey font-weight-normal font-22">
                                  In-Transit Orders
                                </h4>
                                <h1 className="dark-grey t-value mb-0 opacity-dec">
                                  {order_statistics_list?.inTransitCount
                                    ? order_statistics_list?.inTransitCount
                                    : '0'}
                                </h1>
                              </div>
                              <div className="col-md-4 p-0">
                              <div className="text-right">
                                <Image
                                    src={IntransitOrder}
                                    className="img-fluid"
                                  />
                              </div>
                                
                              </div>
                            </div>
                          </div>
                          <div className="bottom-box"></div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer"
                          onClick={() =>
                            this.changepath(`/orders/delivered`)
                          }>
                            <div className="row">
                              <div
                                className="col-md-8 pl-0">
                                <h4 className="color-grey font-weight-normal font-22">
                                  {Template.COMPLETEDORDERS}
                                </h4>
                                <h1 className="dark-grey t-value mb-0 opacity-dec">
                                  {order_statistics_list?.completedCount
                                    ? order_statistics_list?.completedCount
                                    : '0'}
                                </h1>
                              </div>
                              <div className="col-md-4 p-0">
                              <div className="text-right">
                                <Image
                                    src={CompletedOrder}
                                    className="img-fluid"
                                  />
                              </div>
                                
                              </div>
                            </div>
                          </div>
                          <div className="bottom-box"></div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer"
                          onClick={() =>
                            this.changepath(`/orders/cancelled`)
                          }>
                            <div className="row">
                              <div
                                className="col-md-8 pl-0">
                                <h4 className="color-grey font-weight-normal font-22">
                                  Cancelled Orders
                                </h4>
                                <h1 className="dark-grey t-value mb-0 opacity-dec">
                                  {order_statistics_list?.cancelledCount
                                    ? order_statistics_list?.cancelledCount
                                    : '0'}
                                </h1>
                              </div>
                              <div className="col-md-4 p-0">
                              <div className="text-right">
                                <Image
                                    src={CancelOrder}
                                    className="img-fluid"
                                  />
                              </div>
                                
                              </div>
                            </div>
                          </div>
                          <div className="bottom-box"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h3 className="font-weight-normal mb-4 color-33">
                        Site Statistics
                      </h3>
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer p-dec min-ht"
                          onClick={() =>
                            this.changepath(`/processingagentlist`)
                          }>
                            <div className="row">
                              <div className="col-md-4 p-0">
                                <Image src={StatsIco1} className="img-fluid" />
                              </div>
                              <div className="col-md-8 pr-0">
                                <h1 className="dark-grey t-value">
                                  {site_statistics_list?.totalPACount
                                    ? site_statistics_list?.totalPACount
                                    : '0'}
                                </h1>
                                <h4 className="color-grey font-weight-normal font-23">
                                  {' '}
                                  Total Processing Centres
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer p-dec min-ht"
                           onClick={() =>
                            this.changepath(`/deliveryagentlist`)
                          }>
                            <div className="row ">
                              <div className="col-md-4 p-0">
                                <Image src={StatsIco2} className="img-fluid" />
                              </div>
                              <div className="col-md-8 pr-0">
                                <h1 className="dark-grey t-value">
                                  {site_statistics_list?.totalDACount
                                    ? site_statistics_list?.totalDACount
                                    : '0'}
                                </h1>
                                <h4 className="color-grey font-weight-normal font-23">
                                  {' '}
                                  Total Delivery Agents{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer p-dec min-ht"
                          onClick={() =>
                            this.changepath(`/customerlist`)
                          }>
                            <div className="row ">
                              <div className="col-md-4 p-0">
                                <Image src={StatsIco3} className="img-fluid" />
                              </div>
                              <div className="col-md-8 pr-0">
                                <h1 className="dark-grey t-value">
                                  {site_statistics_list?.totalCustomersCount
                                    ? site_statistics_list?.totalCustomersCount
                                    : '0'}
                                </h1>
                                <h4 className="color-grey font-weight-normal font-23">
                                  {' '}
                                  Total Customers{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="dash-b-wrapper pointer p-dec min-ht"
                          onClick={() =>
                            this.changepath(`/opendeliveryrequests`)
                          }>
                            <div className="row">
                              <div className="col-md-4 p-0">
                                <Image src={StatsIco4} className="img-fluid" />
                              </div>
                              <div className="col-md-8 pr-0">
                                <h1 className="dark-grey t-value">
                                  {site_statistics_list?.openDeliveryCount
                                    ? site_statistics_list?.openDeliveryCount
                                    : '0'}
                                </h1>
                                <h4 className="color-grey font-weight-normal font-23">
                                  {' '}
                                  Open Delivery Requests{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                                       
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h3 className="font-weight-normal mb-4 color-33 fs22">
                        Verification & Approvals
                      </h3>
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                          <div className="dash-b-wrapper pointer p-dec bor-btm bor-blue"
                          onClick={() =>
                            this.changepath(`/pending/3`)
                          }>
                            <div className="row">
                              <div className="col-md-7 p-0 pt-4">
                                <h1 className="dark-grey t-value">
                                  {pending_approvals_list?.totalDA
                                    ? pending_approvals_list.totalDA
                                    : '0'}
                                </h1>
                                <h4 className="color-grey font-weight-normal font-22">
                                  {' '}
                                  Total Approvals Pending{' '}
                                </h4>
                              </div>
                              <div className="col-md-5 pl-0">
                                <h3 className="text-center color-blue">
                                  {' '}
                                  Delivery Agent
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="dash-b-wrapper pointer p-dec bor-btm bor-orange"
                          onClick={() =>
                            this.changepath(`/pending/4`)
                          }>
                            <div className="row">
                              <div className="col-md-7 p-0 pt-4">
                                <h1 className="dark-grey t-value">
                                  {pending_approvals_list?.totalPA
                                    ? pending_approvals_list.totalPA
                                    : '0'}
                                </h1>
                                <h4 className="color-grey font-weight-normal font-22">
                                  {' '}
                                  Total Approvals Pending{' '}
                                </h4>
                              </div>
                              <div className="col-md-5 pl-0">
                                <h3 className="text-center color-orange">
                                  {' '}
                                  Processing Agent
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
      // <div className="page__wrapper">
      //     <div className="container-scroller">
      //         <div className="container-fluid page-body-wrapper full-page-wrapper">

      //         </div>
      //     </div>

      // </div>
    );
  }
}
export default AdminDashboard;
// export default withRouter(AdminDashboard);
