import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import profileImgBig from '../../../assets/images/profileImgBig.svg'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import matchSorter from 'match-sorter'
import delivaApiService from '../../../common/services/apiService'
import Pagination from '../DeliveryAgent/perfectpage'
import moment from 'moment'
import profileImg1 from '../../../assets/images/user.png'
import halfStar from '../../../assets/images/halfstar.svg'
import fullStar from '../../../assets/images/fullstar.svg'
import emptyStar from '../../../assets/images/emptystar.svg'
import CONSTANTS from '../../../common/helpers/Constants'

const statusOptions = [
  {
    name: 'Open',
    id: 'open',
  },
  {
    name: 'Accepted',
    id: 'accepted',
  },
  {
    name: 'In-transit',
    id: 'in-transit',
  },
  {
    name: 'Delivered',
    id: 'delivered',
  },
  {
    name: 'Cancelled',
    id: 'cancelled',
  },
]
const datePreset = [
  {
    name: 'Today',
    id: 'today',
  },
  {
    name: 'Yesterday',
    id: 'yesterday',
  },
  {
    name: 'Last 15 days',
    id: 'last15',
  },
  {
    name: 'Last month',
    id: 'lastmonth',
  },
]
class CustomerDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: 'React',
      searchInput: '',
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: 'default', value: 5 }],
      pageSize: 5,
      processingAgentList: [],
      filters: {
        status: '',
        origin: 0,
        destination: 0,
        from: '',
        to: '',
        activeDatePreset: '',
        // sortBy: "",
        // sortOrder: "",
      },
    }
  }

  componentDidMount() {
    delivaApiService('customerProfileDetail', {
      customerId: this.props.match.params.id,
    }).then((response) => {
      if (response && response.data && response.data.resourceData) {
        this.setState({ customerProfile: response.data.resourceData })
      }
    })

    // getProcessingAgentList
    delivaApiService('getProcessingAgentList').then((response) => {
      if (response && response.data && response.data.resourceData) {
        this.setState({ processingAgentList: response.data.resourceData })
      }
    })

    this.customerProfileDeliveryRequestFilter()
  }

  customerProfileDeliveryRequestFilter = () => {
    let data = {
      ...this.state.filters,
      origin: parseInt(this.state.filters.origin),
      destination: parseInt(this.state.filters.destination),
      id: this.props.match.params.id,
    }
    delivaApiService('customerProfileDeliveryRequestFilter', data)
      .then((response) => {
        if (response && response.data) {
          this.setState(
            { delieryRequestData: response.data.resourceData }
            //   ()=>{
            //   this.setState({delieryRequestData: this.state.delieryRequestData?.sort((x, y)=>x.originatingLocation?.localeCompare(y.originatingLocation))})
            // }
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  hello(pageSize) {
    this.setState({
      pageSize,
    })
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = ''
      this.setState({
        filtered: filtered.filter((item) => item.id != 'all'),
        filterAll,
      })
    } else this.setState({ filtered })
  }

  handleFilterInputChange = (type, val) => (e) => {
    let value
    if (type === 'from' || type === 'to') {
      // Convert the input date to a Date object
      const selectedDate = new Date(e.target.value)

      // If type is 'from', set the time to the start of the day (00:00:00.000)
      // If type is 'to', set the time to the end of the day (23:59:59.999)
      if (type === 'from') {
        selectedDate.setHours(0, 0, 0, 0)
      } else if (type === 'to') {
        selectedDate.setHours(23, 59, 59, 999)
      }

      // Convert the modified date back to ISO format
      value = selectedDate.toISOString()
    } else {
      value = val ? val : e.target ? e.target.value : null
    }
    let prev = { ...this.state.filters }
    this.setState({ filters: { ...prev, [type]: value } }, () => {
      // console.log(this.state.filters);
    })
  }

  handleApply = () => {
    this.customerProfileDeliveryRequestFilter()
    this.toggleFilters()
  }

  clearFilter = () => {
    if (this.state.activeDatePreset)
      document
        .getElementById(this.state.activeDatePreset)
        .classList.add('grey-btn')
    if (this.state.status)
      document.getElementById(this.state.status).classList.add('grey-btn')
    this.setState(
      {
        filters: {
          status: '',
          origin: 0,
          destination: 0,
          from: '',
          to: '',
        },
      },
      () => this.customerProfileDeliveryRequestFilter()
    )
  }

  handleDatePreset = (type) => () => {
    let from
    switch (type) {
      case 'today': {
        let prev = { ...this.state.filters }
        this.setState(
          {
            filters: {
              ...prev,
              from: moment().startOf('day').toISOString(),
              to: new Date().toISOString(),
            },
            activeDatePreset: type,
          },
          () => {
            console.log(this.state.filters)
          }
        )
        break
      }
      case 'yesterday': {
        // from =
        let prev = { ...this.state.filters }
        this.setState(
          {
            filters: {
              ...prev,
              from: moment().startOf('day').subtract(1, 'days').toISOString(),
              to: new Date().toISOString(),
            },
            activeDatePreset: type,
          },
          () => {}
        )
        break
      }
      case 'last15': {
        let prev = { ...this.state.filters }
        this.setState(
          {
            filters: {
              ...prev,
              from: moment().startOf('day').subtract(15, 'days').toISOString(),
              to: new Date().toISOString(),
            },
            activeDatePreset: type,
          },
          () => {
            console.log(this.state.filters)
          }
        )
        break
      }
      case 'lastmonth': {
        let prev = { ...this.state.filters }
        this.setState(
          {
            filters: {
              ...prev,
              from: moment().startOf('day').subtract(30, 'days').toISOString(),
              to: new Date().toISOString(),
            },
            activeDatePreset: type,
          },
          () => {
            console.log(this.state.filters)
          }
        )
        break
      }
    }
  }
  toggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters })
  }

  orderStatus = (status) => {
    if (status === CONSTANTS.ORDER_STATUS.TRANSIT) {
      return 'In transit'
    } else if (status === CONSTANTS.ORDER_STATUS.ACCEPTED) {
      return 'Accepted'
    } else if (status === CONSTANTS.ORDER_STATUS.DELIVERED) {
      return 'Delivered'
    } else if (status == CONSTANTS.ORDER_STATUS.CANCELLED) {
      return 'Cancelled'
    } else if (status === CONSTANTS.ORDER_STATUS.REJECTED) {
      return 'Pickup rejected'
    } else if (status === CONSTANTS.ORDER_STATUS.OPEN) {
      return 'Open'
    }
  }

  render() {
    let drawRating = [1, 2, 3, 4, 5]
    const { delieryRequestData, customerProfile } = this.state
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {children}
      </a>
    ))
    // const CustomMenu = React.forwardRef(
    //   ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    //     const { filters, processingAgentList, activeDatePreset } = this.state;
    //     return (

    //     );
    //   }
    // );

    const columns = [
      {
        Header: 'Request ID/Docket no.',
        id: 'requestId',
        accessor: (d) => d.requestId,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "requestId", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['requestId'] }),
        filterAll: true,
      },
      {
        Header: 'Title',
        id: 'title',
        accessor: (d) => d.title,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['title'] }),
        filterAll: true,
      },
      {
        Header: 'Originating location',
        id: 'originatingLocation',
        accessor: (d) => d.originatingLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "originatingLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['originatingLocation'] }),
        filterAll: true,
      },
      {
        Header: 'Destination location',
        id: 'destinationLocation',
        accessor: (d) => d.destinationLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['destinationLocation'] }),
        filterAll: true,
      },
      {
        Header: 'Request date',
        id: 'requestDate',
        accessor: (d) => d.requestDate,
        Cell: (props) => (
          <span>
            {props.value ? moment(props.value).format('DD MMM YYYY') : null}
          </span>
        ), // Custom cell components!
        // accessor: "destinationLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['requestDate'] }),
        filterAll: true,
      },
      {
        Header: 'Request Status',
        id: 'status',
        accessor: (d) => this.orderStatus(d.status),
        Cell: (props) => (
          <span
            className={
              props.value === 'Delivered'
                ? 'text-green'
                : props.value === 'Accepted'
                ? 'text-blue'
                : props.value === 'In transit'
                ? 'text-orange'
                : 'text-red'
            }
          >
            {props.value}
          </span>
        ), // Custom cell components!
        // accessor: "status", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['status'] }),
        filterAll: true,
      },

      // {
      //   Header: "Action",
      //   Cell: (props) => (
      //     <a href className="pointer">
      //       <span className="icon-eye"></span> <span>View</span>
      //     </a>
      //   ), // Custom cell components!
      // },
      {
        Header: 'All',
        id: 'all',
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        noDataText: 'No rows found',
        Filter: () => {},
        getProps: () => {
          return {}
        },
        filterMethod: (filter, rows) => {
          const result = matchSorter(rows, filter.value, {
            keys: [
              'destinationLocation',
              'requestDate',
              'originatingLocation',
              'status',
              'title',
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          })
          return result
        },
        filterAll: true,
      },
    ]
    const { filters, processingAgentList, activeDatePreset } = this.state
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal fs28 mb-0 line-ht color-33">
                        Customer’s Profile
                      </h3>
                      <h5 className="font-weight-normal fs18 mb-0 color-head">
                        <span
                          onClick={() =>
                            this.props.history.push('/customerlist')
                          }
                          className="pointer"
                        >
                          Customers
                        </span>
                        <span className="icon-next mx-2 Fs12"></span> Customer’s
                        Profile{' '}
                      </h5>
                    </Col>
                  </Row>
                  {customerProfile && (
                    <div className="cust-details-wrap mb-4">
                      <Row noGutters className="">
                        <Col md={6} lg={6} className="">
                          <div className="row">
                            <div className="col-sm-4 pr-0">
                              <div className="d-flex justify-content-center">
                                <Image
                                  src={
                                    customerProfile.profileImage
                                      ? customerProfile.profileImage
                                      : profileImg1
                                  }
                                  className="img-fluid max-width-sm rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="col-sm-8 pl-1">
                              <div className="d-flex h-100 align-items-center">
                                <div className="pr-wrap">
                                  <h4>{customerProfile.customerName}</h4>
                                  <div className="ratings my-2">
                                    {drawRating.map((item, i) => {
                                      const ratingFloor = Math.floor(
                                        customerProfile.rating
                                      )
                                      const hasHalfStar =
                                        customerProfile.rating - ratingFloor >=
                                        0.5

                                      if (i < ratingFloor) {
                                        return (
                                          <span key={i}>
                                            <Image
                                              src={fullStar}
                                              className="img-fluid"
                                            />
                                          </span>
                                        )
                                      } else if (
                                        i === ratingFloor &&
                                        hasHalfStar
                                      ) {
                                        return (
                                          <span key={i}>
                                            <Image
                                              src={halfStar}
                                              className="img-fluid"
                                            />
                                          </span>
                                        )
                                      } else {
                                        return (
                                          <span key={i}>
                                            <Image
                                              src={emptyStar}
                                              className="img-fluid"
                                            />
                                          </span>
                                        )
                                      }
                                    })}
                                    <span>{customerProfile?.rating}</span>
                                  </div>
                                  <p className="color-grey">
                                    ID: #{customerProfile.customerId}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={6} lg={6} className="">
                          <div className="d-flex h-100 align-items-center bor-left pl-3">
                            <ul className="pl-2 detail-list mb-0 color-grey">
                              <li>
                                <span className="icon-phone"></span>+{' '}
                                {customerProfile.countryCode}{' '}
                                {customerProfile.mobile}
                              </li>
                              <li>
                                <span className="icon-email"></span>
                                {customerProfile.email}
                              </li>
                              <li className="d-flex">
                                <span className="icon-pin map-ico-user"></span>
                                {customerProfile.address}
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-inline-block w-100">
                        <p className="float-left">Delivery Requests</p>

                        <button
                          className="bor-da-btn warning-btn float-right border-radius-btn"
                          // onClick={this.toggleFilters}
                        >
                          <span className="icon-filter"></span> Filter
                        </button>
                      </div>

                      {/* <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                          className=""
                        >
                          
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu}></Dropdown.Menu>
                      </Dropdown> */}
                      {/* {this.state.showFilters ? ( */}
                      <div
                        className="filter-btn-wrapper mb-4"
                        // ref={ref}
                        // style={style}
                        // className={className}
                        // aria-labelledby={labeledBy}
                      >
                        <p className="color-grey">FILTER BY</p>
                        <p>Request Status</p>
                        <div className="button-wrapper">
                          {statusOptions.map((opt) => (
                            <button
                              id={opt.id}
                              key={opt.id}
                              onClick={this.handleFilterInputChange(
                                'status',
                                opt.id
                              )}
                              className={`bor-btn border-radius-btn ${
                                filters.status === opt.id ? '' : 'grey-btn'
                              }`}
                            >
                              {opt.name}
                            </button>
                          ))}
                        </div>
                        <div className="row mt-4">
                          <div className="col-sm-6">
                            <Form>
                              <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                  <Form.Label>Originating Location</Form.Label>
                                  <Form.Control
                                    as="select"
                                    value={filters.origin}
                                    className="w-100 mt-2"
                                    style={{ color: '#999999' }}
                                    onChange={this.handleFilterInputChange(
                                      'origin'
                                    )}
                                  >
                                    <option value="0">
                                      Originating Location
                                    </option>
                                    {processingAgentList.map((l) => (
                                      <option value={l.id} key={l.id}>
                                        {' '}
                                        {l.businessName} ({l.city})
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Form.Row>
                            </Form>
                          </div>
                          <div className="col-sm-6">
                            <Form>
                              <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                  <Form.Label>Destination Location</Form.Label>
                                  <Form.Control
                                    as="select"
                                    // defaultValue="Choose..."
                                    value={filters.destination}
                                    className="w-100 mt-2"
                                    onChange={this.handleFilterInputChange(
                                      'destination'
                                    )}
                                    style={{ color: '#999999' }}
                                  >
                                    <option value="0">
                                      Destination Location
                                    </option>
                                    {processingAgentList.map((l) => (
                                      <option value={l.id} key={l.id}>
                                        {' '}
                                        {l.businessName} ({l.city})
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              </Form.Row>
                            </Form>
                          </div>
                        </div>
                        <p>Request date</p>
                        <div className="button-wrapper">
                          {datePreset.map((p) => (
                            <button
                              key={p.id}
                              id={p.id}
                              onClick={this.handleDatePreset(p.id)}
                              className={`bor-btn border-radius-btn ${
                                activeDatePreset !== p.id ? 'grey-btn' : ''
                              }`}
                            >
                              {p.name}
                            </button>
                          ))}

                          {/* 
                          <button className="bor-btn grey-btn border-radius-btn">
                            Yesterday
                          </button>
                          <button className="bor-btn border-radius-btn">Last 7 days</button>
                          <button className="bor-btn grey-btn border-radius-btn">
                            Last 15 days
                          </button>
                          <button className="bor-btn grey-btn border-radius-btn">
                            Last month
                          </button> */}
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-4">
                            <Form.Group>
                              <Form.Label className="color-grey">
                                From
                              </Form.Label>
                              <div className="custom-date-format">
                                <Form.Control
                                  className="w-100 mt-2"
                                  type="date"
                                  // value={moment(filters.from || '').format(
                                  //   'YYYY-MM-DD'
                                  // )}
                                  // placeholder=""
                                  onChange={this.handleFilterInputChange(
                                    'from'
                                  )}
                                />
                                <span>
                                  {' '}
                                  {filters?.from
                                    ? moment(filters?.from || '').format(
                                        'DD MMM YYYY'
                                      )
                                    : 'Select Date'}
                                </span>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-sm-4">
                            <Form.Group>
                              <Form.Label className="color-grey">To</Form.Label>
                              <div className="custom-date-format">
                                <Form.Control
                                  className="w-100 mt-2"
                                  type="date"
                                  value={moment(filters.to || '').format(
                                    'YYYY-MM-DD'
                                  )}
                                  placeholder="03 Jan 2020"
                                  onChange={this.handleFilterInputChange('to')}
                                />
                                <span>
                                  {' '}
                                  {filters?.to
                                    ? moment(filters?.to || '').format(
                                        'DD MMM YYYY'
                                      )
                                    : 'Select Date'}
                                </span>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                          <a
                            className="text-uppercase color-grey pointer"
                            onClick={this.clearFilter}
                          >
                            Clear All
                          </a>
                          <button
                            className="bor-btn warning-btn info-btns float-right border-radius-btn"
                            onClick={this.handleApply}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pb-3 fs20">
                    Delivery Requests (
                    {this.state.delieryRequestData &&
                    this.state.delieryRequestData.length
                      ? this.state.delieryRequestData.length
                      : null}
                    )
                  </div>

                  {delieryRequestData && delieryRequestData.length > 0 ? (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="table-wrap table-nowrap-data delivery-request-ordertable">
                          <ReactTable
                            data={delieryRequestData}
                            columns={this.state.pageSize ? columns : []}
                            //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                            defaultPageSize={this.state.defaultPageSize}
                            pageSize={this.state.pageSize}
                            showPageSizeOptions={true}
                            filtered={this.state.filtered}
                            //   resized={this.state.resized}
                            ref={(r) => (this.reactTable = r)}
                            onFilteredChange={this.onFilteredChange.bind(this)}
                            onPageSizeChange={(pageSize, pageIndex) => {
                              console.log(pageSize, pageIndex)
                            }}
                            //   filterable
                            defaultFilterMethod={(filter, row) =>
                              String(row[filter.age]) === filter.value
                            }
                            PaginationComponent={Pagination}
                          />
                          <span className="page-value">
                            Enter Per Page{' '}
                            <input
                              type="text"
                              placeholder="07"
                              name="defaultPageSize"
                              value={this.state.pageSize}
                              onChange={(e) => this.hello(e.target.value)}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">No Records Found</div>
                  )}
                  {/* table ends */}
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default CustomerDetails
